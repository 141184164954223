import React from 'react';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Typography } from 'antd';
import { useLocation } from 'react-router-dom';
const { Text } = Typography;

export default function BreadcrumbList({ items = [] }) {
  const location = useLocation();
  const breadcrumbItems = [
    {
      title:
        location.pathname !== '#/' ? (
          <a href="#/">
            <HomeOutlined />
          </a>
        ) : (
          <HomeOutlined />
        ),
      key: 'home',
    },
    ...items.map((item, index) => ({
      title: item.href ? (
        <a href={item.href}>{item.title}</a>
      ) : (
        <Text color="gray">{item.title}</Text>
      ),
      key: index,
    })),
  ];

  return <Breadcrumb style={{ paddingBottom: 10, paddingLeft: 2 }} items={breadcrumbItems} />;
  /*
    return (
        <Breadcrumb style={{ paddingBottom: 10, paddingLeft: 2 }}>
            <Breadcrumb.Item >
                {location.pathname !== '#/' ? <a href="#/" ><HomeOutlined /></a> : <HomeOutlined />}
            </Breadcrumb.Item>
            {items.map((item, index) => {
                return <Breadcrumb.Item key={index}>

                    {item.href ? <a href={item.href} >{item.title}</a> : <Text color="gray">{item.title}</Text>}
                </Breadcrumb.Item>
            })
            }
        </Breadcrumb>
    )*/
}
