import { MobileAppScreens } from './constants';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_LOADING = 'GET_CATEGORIES_LOADING';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_LOADING = 'CREATE_CATEGORY_LOADING';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_LOADING = 'UPDATE_CATEGORY_LOADING';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';

export const CREATE_ARTICLE = 'CREATE_ARTICLE';
export const CREATE_ARTICLE_LOADING = 'CREATE_ARTICLE_LOADING';
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS';
export const CREATE_ARTICLE_ERROR = 'CREATE_ARTICLE_ERROR';

export const GET_ARTICLES = 'GET_ARTICLES';
export const GET_ARTICLES_LOADING = 'GET_ARTICLES_LOADING';
export const GET_ARTICLES_SUCCESS = 'GET_ARTICLES_SUCCESS';
export const GET_ARTICLES_ERROR = 'GET_ARTICLES_ERROR';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_LOADING = 'DELETE_CATEGORY_LOADING';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';

export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const DELETE_ARTICLE_LOADING = 'DELETE_ARTICLE_LOADING';
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS';
export const DELETE_ARTICLE_ERROR = 'DELETE_ARTICLE_ERROR';

export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';
export const UPDATE_ARTICLE_LOADING = 'UPDATE_ARTICLE_LOADING';
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS';
export const UPDATE_ARTICLE_ERROR = 'UPDATE_ARTICLE_ERROR';

export const getCategories = ({ employerId, screenNames = [], item_per_page, page_number }) => ({
  type: GET_CATEGORIES,
  body: { employerId, screenNames, item_per_page, page_number },
});

export const getAllCategories = ({ employerId }) => ({
  type: GET_CATEGORIES,
  body: {
    employerId,
    screenNames: MobileAppScreens.map(({ value }) => value),
    item_per_page: 100,
    page_number: 1,
  },
});

export const createCategory = (body) => ({ type: CREATE_CATEGORY, body });

export const getArticles = (body) => ({
  type: GET_ARTICLES,
  body: { ...body, item_per_page: 100, page_number: 1 },
});

export const createArticle = (body) => ({ type: CREATE_ARTICLE, body });
export const deleteCategory = ({ employer, id }) => ({
  type: DELETE_CATEGORY,
  body: { employerId: employer, categoryId: id },
});
export const deleteArticle = ({ employer, id }) => ({
  type: DELETE_ARTICLE,
  body: { employerId: employer, articleId: id },
});
export const updateCategory = (body) => ({ type: UPDATE_CATEGORY, body });
export const updateArticle = (body) => ({ type: UPDATE_ARTICLE, body });
