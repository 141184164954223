import React from 'react';
import {
  CheckCircleOutlined,
  SyncOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
  DollarOutlined,
  StopTwoTone,
  StopOutlined,
  UserOutlined,
  RocketOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

export function getIconByStatus(status) {
  switch (status) {
    case 'PROCESSING':
      return <SyncOutlined />;
    case 'COMPLETE':
      return <CheckCircleOutlined />;
    case 'END':
      return <CheckCircleOutlined />;
    case 'ENABLED':
      return <CheckCircleOutlined />;
    case 'OPEN':
      return <CheckCircleOutlined />;
    case 'ACTIVE':
      return <CheckCircleOutlined />;
    case 'ACCEPTED':
      return <CheckCircleOutlined />;
    case 'DRAFT':
      return <MinusCircleOutlined />;
    case 'CLOSED':
      return <CloseCircleOutlined />;
    case 'FAILED':
      return <MinusCircleOutlined />;
    case 'DISABLED':
      return <MinusCircleOutlined />;
    case 'PENDING':
      return <ExclamationCircleOutlined />;

    // Advance
    case 'START':
      return <RocketOutlined />;
    case 'EMPLOYER_FEES':
      return <UserOutlined />;
    case 'EMPLOYEE_FEES':
      return <UserOutlined />;
    case 'EMPLOYER_PAYMENT':
      return <UserOutlined />;
    case 'EMPLOYEE_PAYMENT':
      return <UserOutlined />;

    case 'NONE':
      return <StopOutlined />;
    default:
      return <MinusCircleOutlined />;
  }
}

export function getColorByStatus(status) {
  switch (status) {
    case 'PROCESSING':
      return 'orange';
    case 'COMPLETE':
      return 'success';
    case 'OPEN':
      return 'success';
    case 'ACTIVE':
      return 'success';
    case 'ACCEPTED':
      return 'success';
    case 'ENABLED':
      return 'success';
    case 'FAILED':
      return 'error';
    case 'DISABLED':
      return 'error';
    case 'CLOSED':
      return 'error';
    case 'PENDING':
      return 'warning';
    case 'DRAFT':
      return '#b2b0b2';

    // Advance
    case 'START':
      return 'blue';
    case 'EMPLOYER_FEES':
      return 'purple';
    case 'EMPLOYEE_FEES':
      return 'cyan';
    case 'EMPLOYER_PAYMENT':
      return 'purple';
    case 'EMPLOYEE_PAYMENT':
      return 'cyan';
    case 'END':
      return 'success';
    default:
      return '#b2b0b2';
  }
}
