import { combineReducers } from 'redux';
import {
  GET_REMINDERS_LOADING,
  GET_REMINDERS_SUCCESS,
  GET_REMINDERS_ERROR,

  CREATE_CREATE_REMINDER_LOADING,
  CREATE_CREATE_REMINDER_SUCCESS,
  CREATE_CREATE_REMINDER_ERROR,

  UPDATE_REMINDER_LOADING,
  UPDATE_REMINDER_SUCCESS,
  UPDATE_REMINDER_ERROR,

  RESET_REMINDER
} from './actions';

const initialStateObject = { loading: false, data: {}, error: '' };

const employerReminder = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }

  switch (action.type) {
    case GET_REMINDERS_LOADING: {
      return { ...state, loading: true, error: '' };
    }
    case GET_REMINDERS_SUCCESS: {
      return { loading: false, data: action.data, error: '' };
    }
    case GET_REMINDERS_ERROR: {
      return { ...state, loading: false, error: action.errorMessage };
    }
    case CREATE_CREATE_REMINDER_LOADING: {
      return { ...state, loading: true, error: '' };
    }
    case CREATE_CREATE_REMINDER_SUCCESS: {
      return { loading: false, data: action.data, error: '' };
    }
    case CREATE_CREATE_REMINDER_ERROR: {
      return { ...state, loading: false, error: action.errorMessage };
    }

    case UPDATE_REMINDER_LOADING: {
      return { ...state, loading: true, error: '' };
    }
    case UPDATE_REMINDER_SUCCESS: {
      return { loading: false, data: action.data, error: '' };
    }
    case UPDATE_REMINDER_ERROR: {
      return { ...state, loading: false, error: action.errorMessage };
    }

    case RESET_REMINDER: {
      return initialStateObject;
    }

    default:
      return state;
  }
};

export default combineReducers({ employerReminder });