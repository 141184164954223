import React from 'react';
import { Form, Radio } from 'antd';
import { MobileAppScreens } from '../constants';
import { useArticlesContext } from '../containers/ArticlesContext';

export default function ScreensFilter() {
  const { selectedScreen } = useArticlesContext();

  return (
    <Form
      layout="horizontal"
      colon={true}
      style={{ marginTop: '1rem' }}
      initialValues={{ screen: selectedScreen.value }}
      onValuesChange={(values) => selectedScreen.set(values.screen)}>
      <Form.Item name="screen" label="Screens" wrapperCol={{ span: 6 }}>
        <Radio.Group
          options={[{ label: 'All', value: 'ALL' }, ...MobileAppScreens]}
          optionType="button"
          buttonStyle="solid"
          block
        />
      </Form.Item>
    </Form>
  );
}
