import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import history from './utils/history';
import configureStore from './store/configureStore';
import Navigation from './navigation';
import theme from './assets/theme';
import { ConfigProvider, App as AntApp } from 'antd';

// Create redux store with history
const initialState = {};
const { store, persistor } = configureStore(initialState, history);

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <ConfigProvider theme={theme}>
            <AntApp>
              <Navigation />
            </AntApp>
          </ConfigProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
