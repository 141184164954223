import Paths from '../../utils/paths';

export const breadcrumbListItems = [{ href: Paths.Settings(), title: 'Settings' }, { title: 'Appearance' }]

export const colorSchemeFields = {
  tokens: [
    {
      path: ['colorScheme', 'primary'],
      name: 'Primary Color',
      description: 'Main brand color used for primary buttons and key UI elements',
    },
    {
      path: ['colorScheme', 'text'],
      name: 'Text Color',
      description: 'Default text color used throughout the application',
    },
    {
      path: ['colorScheme', 'link'],
      name: 'Link Color',
      description: 'Color used for hyperlinks and interactive text elements',
    },
    {
      path: ['colorScheme', 'success'],
      name: 'Success Color',
      description: 'Color indicating successful actions or positive status',
    },
    {
      path: ['colorScheme', 'warning'],
      name: 'Warning Color',
      description: 'Color for warning messages and cautionary actions',
    },
    {
      path: ['colorScheme', 'error'],
      name: 'Error Color',
      description: 'Color for error messages and failed states',
    },
    {
      path: ['colorScheme', 'info'],
      name: 'Info Color',
      description: 'Color used for informational messages and hints',
    },
  ],
  backgrounds: [
    {
      path: ['colorScheme', 'background', 'default'],
      name: 'Default Background Color',
      description: 'Default background color for the entire application',
    },
    {
      path: ['colorScheme', 'background', 'body'],
      name: 'Body Background Color',
      description: 'Background color for the main content area',
    },
    {
      path: ['colorScheme', 'background', 'header'],
      name: 'Header Background Color',
      description: 'Background color for the header section',
    },
    {
      path: ['colorScheme', 'background', 'sidebar'],
      name: 'Sidebar Background Color',
      description: 'Background color for the sidebar navigation',
    },
  ],
};


export const mobileColorSchemeFields = {
  tokens: [
    {
      path: ['mobileColorScheme', 'primary'],
      name: 'Primary Color',
      description: 'Main brand color used for primary buttons and key UI elements',
    },
    {
      path: ['mobileColorScheme', 'secondary'],
      name: 'Secondary Color',
      description: 'Secondary color used for buttons and key UI elements',
    },
    {
      path: ['mobileColorScheme', 'success'],
      name: 'Success Color',
      description: 'Color indicating successful actions or positive status',
    },
    {
      path: ['mobileColorScheme', 'warning'],
      name: 'Warning Color',
      description: 'Color for warning messages and cautionary actions',
    },
    {
      path: ['mobileColorScheme', 'error'],
      name: 'Error Color',
      description: 'Color for error messages and failed states',
    },
    {
      path: ['mobileColorScheme', 'info'],
      name: 'Info Color',
      description: 'Color used for informational messages and hints',

    },
    {
      path: ['mobileColorScheme', 'text'],
      name: 'Text Color',
      description: 'Default text color used throughout the application',
    },
    {
      path: ['mobileColorScheme', 'caption'],
      name: 'Caption Color',
      description: 'Color used for captions and secondary text elements',

    },
    {
      path: ['mobileColorScheme', 'buttonTextColor'],
      name: 'Button Text Color',
      description: 'Color used for button text',
    },
    {
      path: ['mobileColorScheme', 'background'],
      name: 'Background Color',
      description: 'Default background color for the entire application',
    }

  ],
};

export const sizesFields = [
  {
    path: ['sizes', 'borderRadius'],
    name: 'Border Radius',
    description: 'Border radius size in pixels for buttons and cards',
    inputProps: {
      min: 0,
      max: 16,
      marks: { 0: 0, 16: 16 },
    },
  },
  {
    path: ['sizes', 'fontSize'],
    name: 'Font Size',
    description: 'Base font size in pixels for regular text',
    inputProps: {
      min: 12,
      max: 32,
      marks: { 12: 12, 32: 32 },
    },
  },
];

export const defaultTheme = {
  isEnabled: false,
  colorScheme: {
    primary: null,
    text: null,
    link: null,
    success: null,
    warning: null,
    error: null,
    info: null,
    background: {
      default: null,
      sidebar: null,
      header: null,
      body: null,
    },
  },
  mobileColorScheme: {
    primary: "96C428",
    secondary: "7BD3EA",
    success: "4caf50",
    warning: "ffeb3b",
    error: "f44336",
    info: "2196f3",
    text: "4F5259",
    caption: "4F5259",
    buttonTextColor: "FFFFFF",
    background: "444659",
  },
  sizes: {
    borderRadius: 2,
    fontSize: 14,
  },
};