import React from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const FileUpload = ({ files, allowMultiple, handleChange }) => {
  return (
    <div className="App">
      <FilePond
        acceptedFileTypes={['text/csv', 'application/vnd.ms-excel', 'text/plain']}
        maxFileSize={'5MB'}
        files={files}
        onupdatefiles={(files) =>
          files.filter((x) => x.status === 2).length !== 0 ? handleChange(files) : null
        }
        allowMultiple={allowMultiple || false}
        maxFiles={3}
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
    </div>
  );
};

export default FileUpload;
