import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { App } from 'antd';
import { EmployerReminderComponent } from '../components';

export default function EmployerReminder() {
  const selectedEmployer = useSelector((state) => state.shared.selectedEmployer);
  const { data: reminders, loading } = useSelector((state) => state.employerReminder.employerReminder);
  const { message } = App.useApp();

  useEffect(() => {
    if (!selectedEmployer?.value) {
      message.error('Please select an employer.');
    }

  }, [selectedEmployer?.value]);




  return (
    <EmployerReminderComponent selectedEmployer={selectedEmployer} reminders={reminders} loading={loading} />
  );
}
