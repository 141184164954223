import { combineReducers } from 'redux';
import {
  GET_MFA_STATUS_LOADING,
  GET_MFA_STATUS_SUCCESS,
  GET_MFA_STATUS_ERROR,
  ASSOCIATE_MFA_LOADING,
  ASSOCIATE_MFA_SUCCESS,
  ASSOCIATE_MFA_ERROR,
  ENABLE_MFA_LOADING,
  ENABLE_MFA_SUCCESS,
  ENABLE_MFA_ERROR,
  DISABLE_MFA_LOADING,
  DISABLE_MFA_SUCCESS,
  DISABLE_MFA_ERROR,
  CLEAR_MFA_DATA,
} from './actions';

const initialStateObject = {
  loading: false,
  data: {},
  error: '',
};

const MFAStatus = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_MFA_STATUS_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_MFA_STATUS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_MFA_STATUS_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const MFA = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case ASSOCIATE_MFA_LOADING:
    case DISABLE_MFA_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }

    case ENABLE_MFA_LOADING: {
      return {
        loading: true,
        data: state.data,
        error: '',
      };
    }

    case ASSOCIATE_MFA_SUCCESS:
    case ENABLE_MFA_SUCCESS:
    case DISABLE_MFA_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case ENABLE_MFA_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.errorMessage,
      };
    }
    case ASSOCIATE_MFA_ERROR:
    case DISABLE_MFA_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }

    case CLEAR_MFA_DATA: {
      return {
        loading: false,
        data: {},
        error: '',
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  MFA,
  MFAStatus,
});
