import { combineReducers } from 'redux';
import {
  GET_DEFAULT_SETTINGS_LOADING,
  GET_DEFAULT_SETTINGS_SUCCESS,
  GET_DEFAULT_SETTINGS_ERROR,
} from './actions';

const initialState = {
  loading: false,
  data: {},
  error: '',
};
const settings = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case GET_DEFAULT_SETTINGS_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_DEFAULT_SETTINGS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_DEFAULT_SETTINGS_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  settings,
});
