import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_EXTERNAL_PAYROLL_EMPLOYEES,
  GET_EXTERNAL_PAYROLL_EMPLOYEES_LOADING,
  GET_EXTERNAL_PAYROLL_EMPLOYEES_SUCCESS,
  GET_EXTERNAL_PAYROLL_EMPLOYEES_ERROR,
  UNBLOCK_EMPLOYEES,
  UNBLOCK_EMPLOYEES_LOADING,
  UNBLOCK_EMPLOYEES_SUCCESS,
  UNBLOCK_EMPLOYEES_ERROR,
  BLOCK_EMPLOYEES,
  BLOCK_EMPLOYEES_LOADING,
  BLOCK_EMPLOYEES_SUCCESS,
  BLOCK_EMPLOYEES_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';

function* getExternalPayrollEmployees({ body }) {
  yield put({ type: GET_EXTERNAL_PAYROLL_EMPLOYEES_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: 'salaryadvance/salary-manager/externalPayroll/{employerId}/employees',
      body,
    });
    yield put({ type: GET_EXTERNAL_PAYROLL_EMPLOYEES_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_EXTERNAL_PAYROLL_EMPLOYEES_ERROR, errorMessage: '' });
  }
}

function* unblockEmployees({ body }) {
  const { employerId } = body;
  yield put({ type: UNBLOCK_EMPLOYEES_LOADING });
  try {
    yield call(api, {
      method: 'POST',
      url: 'salaryadvance/salary-manager/externalPayroll/{employerId}/unblockEmployees',
      body,
    });
    yield put({ type: UNBLOCK_EMPLOYEES_SUCCESS });
    yield put({ type: GET_EXTERNAL_PAYROLL_EMPLOYEES, body: { employerId } });
  } catch (error) {
    errorhandling(error);
    yield put({ type: UNBLOCK_EMPLOYEES_ERROR, errorMessage: '' });
  }
}

function* blockEmployees({ body }) {
  const { employerId } = body;
  yield put({ type: BLOCK_EMPLOYEES_LOADING });
  try {
    yield call(api, {
      method: 'POST',
      url: 'salaryadvance/salary-manager/externalPayroll/{employerId}/blockEmployees',
      body,
    });
    yield put({ type: BLOCK_EMPLOYEES_SUCCESS });
    yield put({ type: GET_EXTERNAL_PAYROLL_EMPLOYEES, body: { employerId } });
  } catch (error) {
    errorhandling(error);
    yield put({ type: BLOCK_EMPLOYEES_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployeeDetailsSaga() {
  yield takeLatest(GET_EXTERNAL_PAYROLL_EMPLOYEES, getExternalPayrollEmployees);
  yield takeLatest(UNBLOCK_EMPLOYEES, unblockEmployees);
  yield takeLatest(BLOCK_EMPLOYEES, blockEmployees);
}
