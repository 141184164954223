import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_PAYROLL_FILE_BY_ID,
  GET_PAYROLL_FILE_BY_ID_LOADING,
  GET_PAYROLL_FILE_BY_ID_SUCCESS,
  GET_PAYROLL_FILE_BY_ID_ERROR,
  GET_PAYROLL_FILE_RECORDS,
  GET_PAYROLL_FILE_RECORDS_LOADING,
  GET_PAYROLL_FILE_RECORDS_SUCCESS,
  GET_PAYROLL_FILE_RECORDS_ERROR,
  UPDATE_RECORDS,
  UPDATE_RECORDS_LOADING,
  UPDATE_RECORDS_ERROR,
  UPDATE_RECORDS_SUCCESS,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';

function* getPayrollFileById({ body }) {
  yield put({ type: GET_PAYROLL_FILE_BY_ID_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.payroll.get_payroll_file_by_id({ payrollId: body.payrollID }),
    });

    yield put({
      type: GET_PAYROLL_FILE_BY_ID_SUCCESS,
      data,
    });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_PAYROLL_FILE_BY_ID_ERROR, errorMessage: '' });
  }
}

function* getPayrollFileRecords({ body }) {
  yield put({ type: GET_PAYROLL_FILE_RECORDS_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.payroll.get_payroll_file_records({
        payrollId: body.payrollID,
        query: {
          page: body.page,
        },
      }),
    });

    yield put({
      type: GET_PAYROLL_FILE_RECORDS_SUCCESS,
      data,
    });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_PAYROLL_FILE_RECORDS_ERROR, errorMessage: '' });
  }
}

function* updateRecord({ body }) {
  yield put({ type: UPDATE_RECORDS_LOADING });
  try {
    const query = {
      payrollId: body.payrollID,
      recordId: body?.record?.id,
    };
    const payload = {
      earningAmount: body.record?.earningAmount,
      units: body.record?.units,
      unitRate: body.record?.unitRate,
    };
    const endpoint = apiConfig.payroll.update_records(query);

    Object.keys(payload).forEach((key) => payload[key] === undefined && delete payload[key]);
    const { data } = yield call(api, {
      method: 'PUT',
      url: endpoint,
      body: payload,
    });
    yield put({
      type: UPDATE_RECORDS_SUCCESS,
      data: data,
    });
    yield put({
      type: GET_PAYROLL_FILE_RECORDS,
      body: {
        payrollID: body.payrollID,
        page: body.page,
      },
    });
    body.onSuccess();
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_RECORDS_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployeeDetailsSaga() {
  yield takeLatest(GET_PAYROLL_FILE_BY_ID, getPayrollFileById);
  yield takeLatest(GET_PAYROLL_FILE_RECORDS, getPayrollFileRecords);
  yield takeLatest(UPDATE_RECORDS, updateRecord);
}
