import React, { useState } from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import { Button, Table, message, Card, Steps, Alert, Typography } from 'antd';
import { UploadOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { FileTypes } from '../../../utils/helper';
import './index.css';
const { Text } = Typography;

const { Step } = Steps;

const CsvFileUpload = ({ expectedColumns, payPeriod, start, uploadFileType }) => {
  const [step, setStep] = useState('upload');
  const [previewData, setPreviewData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);

  const processChunk = async (rows, { startIndex }) => {
    let newErrors = [];
    rows.forEach((row, index) => {
      for (let columnName in row) {
        let validation = expectedColumns.find((x) => x.name === columnName)?.validation;
        if (validation && !row[columnName].match(validation.regex) && !validation.optional) {
          newErrors.push({
            row: startIndex + index,
            columnName: columnName,
            columnValue: row[columnName],
            message: validation.errorMessage,
          });
        }
      }
    });
    setErrors((prev) => [...prev, ...newErrors]);
  };

  const handleImportComplete = ({ file, preview, fields, columnFields }) => {
    setFile(file);
    Papa.parse(file, {
      complete: (results) => {
        setPreviewData(results.data);
        setStep('confirm');
      },
      header: true,
    });
  };

  const handleConfirmUpload = async () => {
    setIsUploading(true);
    try {
      await start({ file, payPeriod });
      message.success('File uploaded successfully');
      setFile(null);
      setPreviewData([]);
      setErrors([]);
      setStep('upload');
    } catch (error) {
      message.error('Upload failed. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const handleCancel = () => {
    setStep('upload');
    setFile(null);
    setPreviewData([]);
    setErrors([]);
  };

  const columns = expectedColumns.map((col) => ({
    title: col.columnName,
    align: 'center',
    dataIndex: col.columnName,
    key: col.columnName,
    minWidth: 'max-content',
  }));

  if (uploadFileType !== FileTypes.EMPLOYEE_DETAILS && !payPeriod) {
    return (
      <Alert
        message="Pay Period Required"
        description="Please select a pay period to upload data."
        type="warning"
        showIcon
      />
    );
  }

  return (
    <div className="csv-file-upload">
      {step === 'upload' && (
        <Importer
          dataHandler={async (rows, { startIndex }) => {
            await processChunk(rows, { startIndex });
          }}
          chunkSize={10000}
          restartable={true}
          onComplete={handleImportComplete}
          skipEmptyLines={false}>
          {expectedColumns.map((item) => (
            <ImporterField
              key={item.name}
              name={item.name}
              label={item.columnName}
              optional={item.validation.optional}
            />
          ))}
        </Importer>
      )}

      {step === 'confirm' && (
        <Card className="confirmation-card">
          <h2>Confirm File Data</h2>
          <p>Please review the rows of your file before confirming the upload:</p>

          <div className="table-container">
            <Table
              dataSource={previewData}
              columns={columns}
              size="large"
              bordered
              pagination={true}
              scroll={{ x: 'max-content' }}
            />
          </div>

          {errors.length > 0 && (
            <Alert
              message="Validation Errors"
              description={
                <div>
                  {errors.map((item, index) => (
                    <div key={index}>
                      Row {item.row}: {item.columnName} [{item.columnValue}] - {item.message}
                    </div>
                  ))}
                  <p>Please correct the CSV data and upload again.</p>
                </div>
              }
              type="error"
              showIcon
              style={{ marginTop: 16, marginBottom: 16 }}
            />
          )}

          <div className="button-container">
            <Button onClick={handleCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleConfirmUpload}
              loading={isUploading}
              icon={<CheckOutlined />}>
              Confirm Upload
            </Button>
          </div>
        </Card>
      )}
    </div>
  );
};

export default CsvFileUpload;
