import { App, Button, Card, Col, ColorPicker, Form, Row, Space, Typography, Modal } from 'antd';
import { mobileColorSchemeFields } from '../constants';
import React, { useState } from 'react';
import { FormItemLabel } from './index';
import { ReloadOutlined } from '@ant-design/icons';
import { isColorTransparent } from '../../../utils/formatString';
import MobilePreview from './MobilePreview';

export default function MobileColorSchemeSection({ onReset, theme, currentTheme, form }) {
    const { modal } = App.useApp();
    const [previewVisible, setPreviewVisible] = useState(false);

    const onRestColorScheme = () => {
        modal.confirm({
            title: 'Reset Mobile Theme Color Scheme',
            content: (
                <>
                    <Typography.Paragraph>
                        <Typography.Text>
                            This action will{' '}
                            <Typography.Text type="danger" strong>
                                reset Mobile theme color scheme
                            </Typography.Text>{' '}
                            to the default settings and{' '}
                            <Typography.Text type="danger" strong>
                                cannot be undone
                            </Typography.Text>
                            .
                        </Typography.Text>
                    </Typography.Paragraph>
                    <Typography.Paragraph style={{ marginTop: 16 }}>
                        Are you sure you want to proceed?
                    </Typography.Paragraph>
                </>
            ),
            footer: (_, { OkBtn, CancelBtn }) => (
                <Space size={'middle'}>
                    <CancelBtn />
                    <OkBtn />
                </Space>
            ),
            okText: 'Yes, Reset',
            cancelText: 'Cancel',
            okType: 'danger',
            onOk: () => onReset('mobileColorScheme'),
        });
    };

    const showPreview = () => {
        setPreviewVisible(true);
    };

    const handleCancel = () => {
        setPreviewVisible(false);
    };


    return (
        <Card
            title="Mobile Color Scheme"
            extra={
                <Space>
                    <Button
                        icon={<ReloadOutlined />}
                        danger={true}
                        onClick={onRestColorScheme}>
                        Reset Mobile Color Scheme
                    </Button>
                    <Button
                        type="primary"
                        onClick={showPreview}>
                        Preview
                    </Button>
                </Space>
            }>
            <Row gutter={[16, 16]}>

                {mobileColorSchemeFields.tokens.map(({ path, name, description }) => (
                    <Col span={8} key={path.join()}>
                        <ColorInputField path={path} label={name} description={description} />
                    </Col>
                ))}
            </Row>
            <Modal
                title="Mobile Preview"
                open={previewVisible}
                styles={{
                    mask: {
                        backgroundColor: 'rgba(0, 0, 0, 0.4)'
                    },
                }}
                mask={true}
                onCancel={handleCancel}
                footer={null}
                centered={true}

                modalRender={modal => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        {modal}
                    </div>
                )}


            >

                <MobilePreview theme={theme} form={form} />
            </Modal>
        </Card>
    );
}

function ColorInputField({ path, label, description }) {
    const getValueFromEvent = (event) => event.toHex();

    const formatColorText = (color) => {
        return isColorTransparent(color?.toHexString()) ? 'Not Set' : color.toHexString();
    };
    return (
        <Form.Item
            name={path}
            getValueFromEvent={getValueFromEvent}
            label={<FormItemLabel label={label} description={description} />}>
            <ColorPicker size="large" allowClear showText={formatColorText} format="hex" />
        </Form.Item>
    );
}