import { combineReducers } from 'redux';
import { GET_EMPLOYERS_LOADING, GET_EMPLOYERS_SUCCESS, GET_EMPLOYERS_ERROR } from './actions';

const initialStateObject = {
  loading: false,
  data: {},
  error: '',
};

const employers = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_EMPLOYERS_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_EMPLOYERS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_EMPLOYERS_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  employers,
});
