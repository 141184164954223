import { Flex, Result } from 'antd';
import React from 'react';

export default function EmployerSelectionPrompt() {
  return (
    <Flex vertical justify={'center'} align={'center'} style={{ height: '80%' }}>
      <Result
        status="warning"
        title="Please choose an Employer"
        subTitle="This page will be loaded once you choose an Employer."
      />
    </Flex>
  );
}
