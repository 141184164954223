import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_LOADING,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { message } from 'antd';
import { apiConfig } from '../../utils/apiConfig';

function* resetPassword({ body }) {
  yield put({ type: RESET_PASSWORD_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: 'auth/RespondToAuthChallenge',
      serverless: true,
      body,
    });
    yield put({ type: RESET_PASSWORD_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: RESET_PASSWORD_ERROR, errorMessage: '' });
  }
}

function* changePassword({ body }) {
  yield put({ type: CHANGE_PASSWORD_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.auth.confirm_password(),
      serverless: true,
      body: body,
    });
    console.log({ data });
    message.success('Your password has been changed successfully.');
    yield put({ type: CHANGE_PASSWORD_SUCCESS, data: { passwordChanged: true } });
  } catch (error) {
    errorhandling(error);
    yield put({ type: CHANGE_PASSWORD_ERROR, errorMessage: '' });
  }
}

function* updatePassword({ body }) {
  yield put({ type: UPDATE_PASSWORD_LOADING });
  try {
    yield call(api, {
      method: 'POST',
      url: apiConfig.auth.change_password(),
      serverless: true,
      body,
    });
    message.success('Your password has been changed successfully.');
    yield put({ type: UPDATE_PASSWORD_SUCCESS, data: { passwordUpdated: true } });
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_PASSWORD_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchLoginSaga() {
  yield takeLatest(CHANGE_PASSWORD, changePassword);
  yield takeLatest(RESET_PASSWORD, resetPassword);
  yield takeLatest(UPDATE_PASSWORD, updatePassword);
}
