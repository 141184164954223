import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_PROGRAMS_BY_INDUSTRY,
  GET_PROGRAMS_BY_INDUSTRY_LOADING,
  GET_PROGRAMS_BY_INDUSTRY_SUCCESS,
  GET_PROGRAMS_BY_INDUSTRY_ERROR,
  GET_EMPLOYEES_BY_EMPLOYER,
  GET_EMPLOYEES_BY_EMPLOYER_LOADING,
  GET_EMPLOYEES_BY_EMPLOYER_SUCCESS,
  GET_EMPLOYEES_BY_EMPLOYER_ERROR,
  GET_EMPLOYER_ADOPTION_RATE,
  GET_EMPLOYER_ADOPTION_RATE_LOADING,
  GET_EMPLOYER_ADOPTION_RATE_SUCCESS,
  GET_EMPLOYER_ADOPTION_RATE_ERROR,
  GET_AVG_AMT_PER_USR_PER_WITHDRAWAL,
  GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_LOADING,
  GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_SUCCESS,
  GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_ERROR,
  GET_AVG_WITHDRAWAL_FREQ_PER_USR,
  GET_AVG_WITHDRAWAL_FREQ_PER_USR_LOADING,
  GET_AVG_WITHDRAWAL_FREQ_PER_USR_SUCCESS,
  GET_AVG_WITHDRAWAL_FREQ_PER_USR_ERROR,
  GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL,
  GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_LOADING,
  GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_SUCCESS,
  GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_ERROR,
  GET_EMPLOYER_DAILY_WITHDRAWAL_DATA,
  GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_LOADING,
  GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_SUCCESS,
  GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';

function* getTotalNoOfProgramsByIndustry({ body }) {
  yield put({ type: GET_PROGRAMS_BY_INDUSTRY_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: 'salaryadvance/salary-manager/superAdmin/dashboard/totalPrograms',
      body,
    });
    yield put({ type: GET_PROGRAMS_BY_INDUSTRY_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_PROGRAMS_BY_INDUSTRY_ERROR, errorMessage: '' });
  }
}

function* getTotalNoOfEmployeesByEmployer({ body }) {
  yield put({ type: GET_EMPLOYEES_BY_EMPLOYER_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: 'salaryadvance/salary-manager/superAdmin/dashboard/totalEmployees',
      body,
    });
    yield put({ type: GET_EMPLOYEES_BY_EMPLOYER_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_EMPLOYEES_BY_EMPLOYER_ERROR, errorMessage: '' });
  }
}

function* getEmployerAdoptionRate({ body }) {
  yield put({ type: GET_EMPLOYER_ADOPTION_RATE_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: 'salaryadvance/salary-manager/adoption',
      body,
    });
    yield put({ type: GET_EMPLOYER_ADOPTION_RATE_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_EMPLOYER_ADOPTION_RATE_ERROR, errorMessage: '' });
  }
}

function* getAvgAmountPerUserPerWithdrawal({ body }) {
  yield put({ type: GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: 'salaryadvance/salary-manager/superAdmin/dashboard/avgAmtUserWithdrawal',
      body,
    });
    yield put({ type: GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_ERROR, errorMessage: '' });
  }
}

function* getAvgWithdrawalFrequencyPerUser({ body }) {
  yield put({ type: GET_AVG_WITHDRAWAL_FREQ_PER_USR_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: 'salaryadvance/salary-manager/superAdmin/dashboard/avgUserWithdrawalFreq',
      body,
    });
    yield put({ type: GET_AVG_WITHDRAWAL_FREQ_PER_USR_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_AVG_WITHDRAWAL_FREQ_PER_USR_ERROR, errorMessage: '' });
  }
}

function* getPercentWithdrawalFromAvailBalance({ body }) {
  yield put({ type: GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: 'salaryadvance/salary-manager/superAdmin/dashboard/perWithdrawalFromAvailBal',
      body,
    });
    yield put({ type: GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_ERROR, errorMessage: '' });
  }
}

function* getEmployerDailyWithdrawalData({ body }) {
  yield put({ type: GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: 'salaryadvance/salary-manager/superAdmin/dashboard/totalWithdrawls',
      body,
    });
    yield put({ type: GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchSuperAdminDashboardSaga() {
  yield takeLatest(GET_PROGRAMS_BY_INDUSTRY, getTotalNoOfProgramsByIndustry);
  yield takeLatest(GET_EMPLOYEES_BY_EMPLOYER, getTotalNoOfEmployeesByEmployer);
  yield takeLatest(GET_EMPLOYER_ADOPTION_RATE, getEmployerAdoptionRate);
  yield takeLatest(GET_AVG_AMT_PER_USR_PER_WITHDRAWAL, getAvgAmountPerUserPerWithdrawal);
  yield takeLatest(GET_AVG_WITHDRAWAL_FREQ_PER_USR, getAvgWithdrawalFrequencyPerUser);
  yield takeLatest(GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL, getPercentWithdrawalFromAvailBalance);
  yield takeLatest(GET_EMPLOYER_DAILY_WITHDRAWAL_DATA, getEmployerDailyWithdrawalData);
}
