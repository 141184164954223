import { FileTypes } from './helper';

const csvTemplates = {
  [FileTypes.EARNINGS]: {
    header: 'workNumber,employeeName,description,earningAmount',
    exampleData: '987654321,Jane Smith,Worked on project,1000',
  },
  [FileTypes.DAILY_SHIFT_EARNINGS]: {
    header: 'workNumber,date,employeeName,units,description,unitRate',
    exampleData: '987654321,2024-08-20,Jane Smith,8,Worked on project,50',
  },
  [FileTypes.EMPLOYEE_DETAILS]: {
    header:
      'workNumber,nationalInsuranceNumber,firstName,lastName,email,phoneNumber,accountNumber,sortCode,position,dateOfBirth',
    exampleData:
      '123456789,AB123456C,John,Doe,johndoe@example.com,+441234567890,12345678,123456,Software Engineer,1990-01-01',
  },
};

const generateCsvContent = (uploadFileType) => {
  const template = csvTemplates[uploadFileType];

  if (!template) {
    throw new Error(`Unsupported upload file type: ${uploadFileType}`);
  }

  const { header, exampleData } = template;
  return `${header}\n${exampleData}`;
};

export const downloadCsvExample = (uploadFileType) => {
  const csvContent = generateCsvContent(uploadFileType);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `sample_${uploadFileType}_payroll_data.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const convertSvgToPng = async (file) => {
  // Read file contents
  const fileText = await file.text();
  // Create an image element to load the SVG
  const img = new Image();

  // Return a promise that resolves with the PNG blob
  return new Promise((resolve, reject) => {
    img.onload = () => {
      // Create canvas with the same dimensions as the loaded image
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');

      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0);

      // Convert canvas to PNG blob
      canvas.toBlob(
        (blob) => (blob ? resolve(blob) : reject(new Error('Failed to parse SVG file'))),
        'image/png',
        1 // quality
      );
    };

    // Handle image loading errors
    img.onerror = () => reject(new Error('Failed to load SVG'));

    // Create a data URL from the SVG text
    img.src = `data:image/svg+xml;base64,${btoa(fileText)}`;
  });
};
