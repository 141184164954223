import React from 'react';
import { DoubleRightOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Card, Col, Collapse, Empty, Flex, Popover, Row, Tag, Typography } from 'antd';
import { HiOutlineSquares2X2 } from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryIconsList } from '../constants';
import { useArticlesContext } from '../containers/ArticlesContext';
import { AddNewDropDown, ArticlesList, ScreensFilter } from './index';
import { deleteCategory } from '../actions';
import { DeletePopconfirm } from '../../../shared';

// generate map of icons (sting value to IconComponent)
const ICONS_MAP = Object.fromEntries(CategoryIconsList.map(({ value, icon }) => [value, icon]));

export default function CategoriesList() {
  const { selectedScreen } = useArticlesContext();
  const { data: categories, loading: categoriesLoading } = useSelector(
    (state) => state.library.categories
  );
  const { data: articles, loading: articlesLoading } = useSelector(
    (state) => state.library.articles
  );

  const collapseItems = React.useMemo(() => {
    const screenName = selectedScreen.value;
    const listCategories = filterByScreenName(categories, screenName);

    return listCategories.map((category, index) => {
      // sort by order (ascending) for this category
      const categoryArticles = (articles.byCategory[category.id] || [])
        .sort((a, b) => a.order - b.order)
        .map(({ article }) => articles.byId[article])
        .filter(({ isDeleted }) => !isDeleted);

      return {
        key: `${index}-${category.id}-${screenName}`,
        label: (
          <CategoryCollapseLabel
            category={category}
            screenName={screenName}
            articlesCount={categoryArticles?.length || 0}
          />
        ),
        extra: <CategoryControls category={category} />,
        // children: <pre>{JSON.stringify(categoryArticles, null, 2)}</pre>, //<ArticlesList articles={categoryArticles || []} loading={articlesLoading} />,
        children: <ArticlesList articles={categoryArticles || []} loading={articlesLoading} />,
      };
    });
  }, [categories, articles, selectedScreen.value, categoriesLoading, articlesLoading]);

  function filterByScreenName(categories, screenName) {
    if (screenName === 'ALL') {
      return Object.values(categories.byId).filter((category) => !category.isDeleted);
    }
    const categoryIds = categories.byScreenName[screenName]?.map(({ id }) => id) || [];
    return categoryIds.map((id) => categories.byId[id]).filter(({ isDeleted }) => !isDeleted);
  }

  if (categoriesLoading) {
    return <Card title={<ScreensFilter />} loading={true} />;
  }

  return (
    <Card title={<ScreensFilter />} extra={<AddNewDropDown />}>
      {collapseItems.length === 0 && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No categories found." />
      )}
      <Collapse
        items={collapseItems}
        size={'large'}
        bordered={false}
        expandIcon={({ isActive }) => (
          <DoubleRightOutlined style={{ fontSize: 18 }} rotate={isActive ? 90 : 0} />
        )}
      />
    </Card>
  );
}

const CategoryCollapseLabel = React.memo(({ category, screenName, articlesCount }) => {
  const CategoryIcon = ICONS_MAP[category.icon] || HiOutlineSquares2X2;
  const isAllScreens = screenName === 'ALL';

  return (
    <Row gutter={[8, 8]}>
      <Col span={8}>
        <Flex align={'center'} gap={'middle'}>
          <CategoryIcon size={18} />
          {category.name}
        </Flex>
      </Col>
      <Col span={3}>
        <Tag>{articlesCount || 'No'} article(s)</Tag>
      </Col>
      <Col span={isAllScreens ? 8 : 0}>
        {isAllScreens &&
          category.screens?.map(({ name }) => <Tag key={name + category.id}>{name}</Tag>)}
      </Col>
    </Row>
  );
});

const CategoryControls = React.memo(({ category }) => {
  const dispatch = useDispatch();
  const { openDrawer } = useArticlesContext();
  const onDelete = () => {
    dispatch(deleteCategory(category));
  };
  const onEdit = () => {
    openDrawer.set({ key: 'edit-category', data: category });
  };

  return (
    <Flex gap="middle" onClick={(event) => event.stopPropagation()}>
      <Popover
        content={
          <Typography.Paragraph style={{ maxWidth: '20rem' }}>
            {category.description}
          </Typography.Paragraph>
        }
        title={<Typography.Text strong>{category.name}</Typography.Text>}
        trigger="click"
        placement="bottomLeft">
        <Button icon={<EyeOutlined />}>View</Button>
      </Popover>

      <Button icon={<EditOutlined />} onClick={onEdit}>
        Edit
      </Button>
      <DeletePopconfirm
        onDelete={onDelete}
        title={`Delete the category: ${category.name}`}
        description="Are you sure you want to delete this category?"
      />
    </Flex>
  );
});
