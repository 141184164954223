import React, { useMemo, useState } from 'react';
import { Button, Card, Col, Flex, Image, Row, Upload } from 'antd';
import { DeleteOutlined, FormOutlined, InboxOutlined } from '@ant-design/icons';
import EditImageModal from './EditImageModal';
import { convertSvgToPng } from '../../../../utils/formatFiles';

const { Dragger } = Upload;

const LogoPreviewComponent = ({ themeLogoUrl, previewLogo, theme }) => {
  const src = previewLogo || themeLogoUrl;
  if (!src) return <></>;

  const bgColor = theme?.colorScheme?.background?.sidebar;
  return (
    <Col
      lg={12}
      md={24}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Flex
        style={{ height: '100%', padding: '1rem', backgroundColor: `#${bgColor}` }}
        className={'logo'}
        justify={'center'}
        align={'center'}>
        <Image src={src} preview={true} style={{ maxHeight: '10rem', maxWidth: '20rem' }} />
      </Flex>
    </Col>
  );
};

export default function ImageInput({
  defaultImageURL,
  setImageFile,
  imageFile,
  label,
  description,
  aspectRatio,
  theme,
}) {
  const [editModalOpen, setEditModalOpen] = useState(false); // Controls modal visibility

  const imageFileUrl = useMemo(() => {
    return imageFile ? URL.createObjectURL(imageFile) : defaultImageURL || null;
  }, [imageFile]);

  const onRemoveFile = () => {
    setImageFile(null);
  };

  const onFileChange = async (file) => {
    if (!file) return false; // No file, exit early

    // convert svg files to png before starting the edit
    const image = file.type === 'image/svg+xml' ? await convertSvgToPng(file) : file;

    // Open modal only if setting image is successful
    setImageFile(image) && setEditModalOpen(true);
    return false;
  };

  const onEditComplete = (editedFile) => {
    setImageFile(editedFile);
  };

  return (
    <Col xs={24} md={11}>
      <Card title={label} extra={description}>
        <Row
          gutter={[16, 16]}
          style={{ paddingBottom: '1rem' }}
          justify={'center'}
          align={'middle'}>
          <Col lg={12} md={24}>
            <Dragger
              multiple={false}
              maxCount={1}
              listType={'picture-card'}
              accept={'image/*'}
              onRemove={onRemoveFile}
              beforeUpload={onFileChange}
              showUploadList={false}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                <b>Click</b> or <b>Drag</b> you Logo file to this area
              </p>
            </Dragger>
          </Col>
          <LogoPreviewComponent
            previewLogo={imageFileUrl}
            themeLogoUrl={defaultImageURL}
            theme={theme}
          />
        </Row>

        {imageFile ? (
          <Flex justify={'center'} gap={'middle'}>
            <Button type="text" danger onClick={onRemoveFile} icon={<DeleteOutlined />}>
              Remove
            </Button>
            <Button
              size={'middle'}
              type="primary"
              onClick={() => setEditModalOpen(true)}
              icon={<FormOutlined />}>
              Edit
            </Button>
          </Flex>
        ) : (
          <></>
        )}
      </Card>
      {editModalOpen && (
        <EditImageModal
          label={label}
          onCancel={() => setEditModalOpen(false)}
          isOpen={editModalOpen}
          imageFile={imageFile}
          setImageFile={setImageFile}
          imageFileUrl={imageFileUrl}
          aspect={aspectRatio}
          onEditComplete={onEditComplete}
        />
      )}
    </Col>
  );
}
