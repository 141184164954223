import React from 'react';
import { Card, Button, Tooltip } from 'antd';
import {
    HomeOutlined,
    UserOutlined,
    HeartOutlined,
    QuestionCircleOutlined,
    LineChartOutlined,
    DollarOutlined, BookOutlined,
    ExclamationCircleOutlined,
    ToolOutlined
} from '@ant-design/icons';
import './MobilePreview.css';

const IPhoneFrame = ({ children, colors }) => (
    <div className="iphone-frame">
        <div className="iphone-notch">
            <div className="iphone-speaker" />
            <div className="iphone-camera" />
        </div>
        <div className="iphone-screen">
            <div className="status-bar" style={{ backgroundColor: colors.background }}>
                <span className="time">
                    {new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}
                </span>
                <div className="icons">
                    <div className="signal" />
                    <div className="wifi" />
                    <div className="battery" />
                </div>
            </div>
            {children}
        </div>
    </div>
);

const LoginPreview = ({ colors, logos }) => (
    <ColorTooltip colorName="background" colorValue={colors.background}>
        <div className="login-preview" style={{ background: colors.background }}>
            <div className="login-content">
                <div className="logo-container">
                    <Tooltip title={`Full Size Logo`}> <img src={logos.main} alt="Advancia" className="logo" /> </Tooltip>
                </div>
                <div className="welcome-text">
                    <h1>Welcome to <br /> Advancia</h1>
                    <p>Advance your Wellbeing</p>
                    <p>Financial, Physical, Mental Wellness.</p>
                </div>
            </div>
            <div className="login-buttons">

                <ColorTooltip colorName="primary" colorValue={colors.primary}>
                    <Button type="primary" className="contained button" style={{ backgroundColor: colors.primary }}>
                        Log In
                    </Button>
                </ColorTooltip>
                <ColorTooltip colorName="primary" colorValue={colors.primary}>
                    <Button type="link" className="outlined button" style={{
                        borderColor: colors.primary, color: colors.primary,

                    }}>
                        Sign Up
                    </Button>
                </ColorTooltip>
                <p className="terms-text">
                    No interest, penalties, late fees, or credit checks
                </p>
            </div>
        </div>
    </ColorTooltip>
);

const MainPagePreview = ({ colors, logos }) => (
    <div className="main-preview">
        <ColorTooltip colorName="background" colorValue={colors.background}>
            <div className="header" style={{ backgroundColor: colors.background }}>
                <h1>Hello Employee,</h1>
                <p>Welcome to Advancia</p>
            </div>
        </ColorTooltip>
        <div className="main-content">
            <SectionTitle icon={<ColorTooltip colorName="primary" colorValue={colors.primary}><DollarOutlined style={{ color: colors.primary }} /></ColorTooltip>} title="Access Earned Income" />
            <BalanceCard colors={colors} />
            <FinancialTools colors={colors} />
            <FeaturedReadings colors={colors} />
        </div>
        <TabNavigation colors={colors} logos={logos} />
    </div>
);

const SectionTitle = ({ icon, title }) => (
    <div className="section-title">
        {icon}
        <span>{title}</span>
    </div>
);

const ColorTooltip = ({ colorName, colorValue, children }) => (
    <Tooltip title={`${colorName}: ${colorValue}`}>
        {children}
    </Tooltip>
);

const BalanceCard = ({ colors }) => (
    <div className="balance-card">
        <div className="balance-info">
            <ColorTooltip colorName="caption" colorValue={colors.caption}> <p style={{ color: colors.caption }}>Available Balance</p> </ColorTooltip>
            <ColorTooltip colorName="text" colorValue={colors.text}> <h2 style={{ color: colors.text }}>£637.07</h2> </ColorTooltip>
        </div>
        <ColorTooltip colorName="secondary" colorValue={colors.secondary}>
            <Button type="primary" className="advance-button" style={{ backgroundColor: colors.secondary }}>
                Advance it Now!
            </Button>
        </ColorTooltip>
        <ColorTooltip colorName="caption" colorValue={colors.caption}>
            <p className="how-it-works" style={{ color: colors.caption }}>
                How it works? <ExclamationCircleOutlined />
            </p>
        </ColorTooltip>
    </div>
);

const FinancialTools = ({ colors }) => (
    <div className="financial-tools">
        <SectionTitle icon={<ColorTooltip colorName="primary" colorValue={colors.caption}><ToolOutlined style={{ color: colors.primary }} /></ColorTooltip>} title="Financial Tools" />
        <div className="tools-grid">
            {[
                { name: 'Budgeting', icon: <LineChartOutlined /> },
                { name: 'Prevent Overdrawn Fees', icon: <DollarOutlined /> },
                { name: 'Pay Yourself First', icon: <DollarOutlined /> }
            ].map((tool) => (
                <ToolCard key={tool.name} tool={tool} colors={colors} />
            ))}
        </div>
    </div>
);

const ToolCard = ({ tool, colors }) => (
    <div className="tool-card">
        <ColorTooltip colorName="primary" colorValue={colors.primary}>
            <div className="tool-icon" style={{ color: colors.primary }}>{tool.icon}</div>
        </ColorTooltip>
        <p>{tool.name}</p>
    </div>
);

const FeaturedReadings = ({ colors }) => (
    <div className="financial-tools">
        <SectionTitle icon={<ColorTooltip colorName="primary" colorValue={colors.primary}><BookOutlined style={{ color: colors.primary }} /></ColorTooltip>} title="Featured Readings for the Week" />
        <div className="featured-grid">
            {[
                { name: 'Mind Tips for Wellbeing', image: <img src="https://placehold.co/200x120" alt="Mind Tips" /> },
                { name: 'Managing low income', image: <img src="https://placehold.co/200x120" alt="Managing low income" /> }
            ].map((featured) => (
                <FeaturedCard key={featured.name} featured={featured} colors={colors} />
            ))}
        </div>
    </div>
);

const FeaturedCard = ({ featured, colors }) => (
    <div className="featured-card">
        <ColorTooltip colorName="primary" colorValue={colors.primary}>
            <div style={{ color: colors.primary }}>{featured.image}</div>
        </ColorTooltip>
        <p>{featured.name}</p>
    </div>
);

const TabNavigation = ({ colors, logos }) => (
    <div className="tab-navigation">
        {[
            { icon: <LineChartOutlined style={{ fontSize: 18 }} />, label: 'Track' },
            { icon: <HeartOutlined style={{ fontSize: 18 }} />, label: 'Wellbeing' },
            {
                icon: <Tooltip title={`Icon Size Logo`}> <img src={logos.icon} alt="Logo" className="home-logo" /> </Tooltip>, label: 'Home', isHome: true
            },
            { icon: <UserOutlined style={{ fontSize: 18 }} />, label: 'Profile' },
            { icon: <QuestionCircleOutlined style={{ fontSize: 18 }} />, label: 'Help me' }
        ].map((item, index) => (
            <NavItem key={index} item={item} colors={colors} />
        ))}
    </div>
);

const NavItem = ({ item, colors }) => (
    <div className={`nav-item ${item.isHome ? 'home' : ''}`} style={{ color: colors.caption }}>
        <ColorTooltip colorName={item.isHome ? "background" : "caption"} colorValue={item.isHome ? colors.background : colors.caption}>
            <div className={` ${item.isHome ? 'home-icon' : ''}`} style={{ backgroundColor: item.isHome ? colors.background : 'transparent' }}>
                {item.icon}
            </div>
        </ColorTooltip>
        <ColorTooltip colorName={item.isHome ? "background" : "caption"} colorValue={item.isHome ? colors.background : colors.caption}>
            <span style={{ color: item.isHome ? colors.background : colors.caption, fontWeight: item.isHome ? 'bold' : 'normal' }}>{item.label}</span>
        </ColorTooltip>
    </div>
);

const MobilePreview = ({ theme, form }) => {
    const { mobileColorScheme } = form.getFieldsValue();
    const colors = Object.entries(mobileColorScheme).reduce((acc, [key, value]) => ({ ...acc, [key]: `#${value}` }), {});
    const logos = { main: theme.logo?.fullVersionUrl, icon: theme.logo?.iconVersionUrl };
    return (
        <div className="mobile-preview-container">
            <div className="preview-wrapper">
                <IPhoneFrame colors={colors}>
                    <LoginPreview colors={colors} logos={logos} />
                </IPhoneFrame>
            </div>
            <div className="preview-wrapper">
                <IPhoneFrame colors={colors}>
                    <MainPagePreview logos={logos} colors={colors} />
                </IPhoneFrame>
            </div>
        </div>
    );
};

export default MobilePreview;
