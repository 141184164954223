import { isObject, set } from 'lodash';

// Helper functions to set a nested property if it exists
function setColor(obj, path, hexValue) {
  if (hexValue !== undefined && hexValue !== null) {
    set(obj, path, `#${hexValue}`);
  }
}

function setValue(obj, path, value) {
  if (value !== undefined && value !== null) {
    set(obj, path, value);
  }
}

export default function mapToConfigProviderTheme(theme) {
  const { colorScheme, sizes } = theme;
  const updatedTheme = {};

  // colorScheme related properties
  if (isObject(colorScheme)) {
    // tokens
    setColor(updatedTheme, 'token.colorError', colorScheme?.error);
    setColor(updatedTheme, 'token.colorInfo', colorScheme?.info);
    setColor(updatedTheme, 'token.colorLink', colorScheme?.link);
    setColor(updatedTheme, 'token.colorPrimary', colorScheme?.primary);
    setColor(updatedTheme, 'token.colorSuccess', colorScheme?.success);
    setColor(updatedTheme, 'token.colorWarning', colorScheme?.warning);
    setColor(updatedTheme, 'token.colorTextBase', colorScheme?.text);
    setColor(updatedTheme, 'token.colorBgBase', colorScheme?.background?.default);

    // components
    setColor(updatedTheme, 'components.Button.colorPrimary', colorScheme?.primary);
    setColor(updatedTheme, 'components.Layout.bodyBg', colorScheme?.background?.body);
    setColor(updatedTheme, 'components.Layout.headerBg', colorScheme?.background?.header);
    setColor(updatedTheme, 'components.Layout.siderBg', colorScheme?.background?.sidebar);
  }

  // sizes related properties
  if (isObject(sizes)) {
    setValue(updatedTheme, 'token.borderRadius', sizes?.borderRadius);
    setValue(updatedTheme, 'token.fontSize', sizes?.fontSize);
  }

  return updatedTheme;
}
