import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactCrop, { convertToPixelCrop } from 'react-image-crop';
import { FormOutlined } from '@ant-design/icons';
import { App, ColorPicker, InputNumber, Modal, Slider, Space } from 'antd';
import Spinner from '../../../../shared/components/Spinner';
import 'react-image-crop/dist/ReactCrop.css';
import { centerAspectCrop, createImage, scaleDimensions } from './cropUtils';

const maxModalWidth = 700;
const maxModalHeight = 400;

function EditImageModal({ isOpen, onCancel, label, imageFile, aspect, onEditComplete }) {
  const { message } = App.useApp();

  const [imgSrc, setImgSrc] = useState('');
  const imgRef = useRef(null);
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });

  const [crop, setCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState('rgba(255,255,255,0)');
  const [corpWidth, setCorpWidth] = useState(90);
  const formattedBackgroundColor = useMemo(
    () => (typeof backgroundColor === 'string' ? backgroundColor : backgroundColor?.toHexString()),
    [backgroundColor]
  );

  // Load the imageFile and update imgSrc
  useEffect(() => {
    // Reset crop on new image file load
    setCrop(undefined);

    // Exit if no new image file is provided or image source is already set
    if (!imageFile || imgSrc) return;

    // FileReader to read image file
    const reader = new FileReader();
    reader.onload = () => {
      const imgSrcResult = reader.result?.toString() || '';
      setImgSrc(imgSrcResult);

      // Load image to get dimensions for scaling
      const img = new Image();
      img.onload = () => {
        const scaledDimensions = scaleDimensions({
          width: img.width,
          height: img.height,
          maxWidth: maxModalWidth,
          maxHeight: maxModalHeight,
        });
        setImgDimensions(scaledDimensions);
      };
      img.src = imgSrcResult;
    };

    reader.readAsDataURL(imageFile);
  }, [imageFile]);

  // Reset state on unmount
  useEffect(() => {
    return () => {
      setCrop(null);
      setRotate(0);
      setScale(1);
      setBackgroundColor(null);
      setImgSrc(null);
      setImgDimensions({ width: 0, height: 0 });
    };
  }, []);

  // Set crop to center of image on load
  useEffect(() => {
    if (imgRef.current && imgDimensions.width && imgDimensions.height) {
      setCrop(centerAspectCrop(imgDimensions.width, imgDimensions.height, aspect, corpWidth));
    }
  }, [corpWidth, imgDimensions]);

  function onImageLoad(e) {
    const { width, height } = imgDimensions;
    setCrop(centerAspectCrop(width, height, aspect, corpWidth));
  }

  const handleOk = async () => {
    try {
      const completedCrop = convertToPixelCrop(crop, imgDimensions.width, imgDimensions.height);
      const blob = await createImage({
        image: imgRef.current,
        crop: completedCrop,
        rotate,
        scale,
        backgroundColor: formattedBackgroundColor,
      });
      const newFile = new File([blob], 'logo.png', { type: 'image/png', quality: 1 });
      onEditComplete(newFile);
    } catch (e) {
      message.error('Error cropping image');
    } finally {
      onCancel();
    }
  };

  return (
    <Modal
      width={800}
      title={
        <Space size={'small'}>
          <FormOutlined /> Edit {label}
        </Space>
      }
      okText={'Apply Changes'}
      maskClosable={false}
      style={{ top: 40 }}
      styles={{
        body: {
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto',
        },
      }}
      open={isOpen}
      destroyOnClose={true}
      onCancel={onCancel}
      onOk={handleOk}>
      {!imgSrc ? (
        <Spinner loading={true} />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '20px',
          }}>
          <div
            style={{
              maxWidth: maxModalWidth,
              maxHeight: maxModalHeight,
              margin: '0 auto',
              overflow: 'hidden',
            }}>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              aspect={aspect}
              locked
              style={{
                backgroundColor: formattedBackgroundColor,
                maxWidth: '100%',
                maxHeight: '100%',
              }}>
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                  maxWidth: '100%',
                  maxHeight: '100%',
                  width: imgDimensions.width,
                  height: imgDimensions.height,
                  objectFit: 'contain',
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </div>

          {/* Controls Section */}
          <div style={{ marginTop: 20 }}>
            {/* Scale Control */}
            <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
              <span style={{ width: 100 }}>Scale:</span>
              <Slider
                min={0.1}
                step={0.05}
                max={3}
                value={scale}
                onChange={setScale}
                style={{ flex: 1 }}
              />
              <InputNumber
                min={0.1}
                max={5}
                step={0.05}
                value={scale}
                onChange={setScale}
                style={{ width: 80, marginLeft: 16 }}
              />
            </div>

            {/* Rotation Control */}
            <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
              <span style={{ width: 100 }}>Rotation:</span>
              <Slider
                min={0}
                max={360}
                value={rotate}
                onChange={setRotate}
                style={{ flex: 1 }}
                marks={{
                  0: '0°',
                  90: '90°',
                  180: '180°',
                  270: '-90°',
                  360: '0°',
                }}
              />
              <InputNumber
                min={0}
                max={360}
                value={rotate}
                onChange={setRotate}
                style={{ width: 80, marginLeft: 16 }}
              />
            </div>

            {/* Corp Width Control */}
            <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
              <span style={{ width: 100 }}>Corp Width:</span>
              <Slider
                min={10}
                max={100}
                value={corpWidth}
                onChange={setCorpWidth}
                style={{ flex: 1 }}
                marks={{
                  10: '10%',
                  50: '50%',
                  100: '100%',
                }}
              />
              <InputNumber
                min={10}
                max={100}
                value={corpWidth}
                onChange={setCorpWidth}
                style={{ width: 80, marginLeft: 16 }}
              />
            </div>

            {/* Background Color Control */}
            <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
              <span style={{ width: 100 }}>Background:</span>
              <ColorPicker
                showText={true}
                allowClear={true}
                value={backgroundColor}
                onChange={(color) => setBackgroundColor(color.toHexString())}
                showAlpha
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default EditImageModal;
