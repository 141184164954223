import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_EMPLOYER_PAY_CYCLE_LIST,
  GET_EMPLOYER_PAY_CYCLE_LIST_LOADING,
  GET_EMPLOYER_PAY_CYCLE_LIST_SUCCESS,
  GET_EMPLOYER_PAY_CYCLE_LIST_ERROR,
  GET_PAY_PERIOD_LIST,
  GET_PAY_PERIOD_LIST_LOADING,
  GET_PAY_PERIOD_LIST_SUCCESS,
  GET_PAY_PERIOD_LIST_ERROR,
  REFRESH_PAY_PERIOD_LIST,
  REFRESH_PAY_PERIOD_LIST_LOADING,
  REFRESH_PAY_PERIOD_LIST_SUCCESS,
  REFRESH_PAY_PERIOD_LIST_ERROR,
  START_PAY_PERIOD,
  START_PAY_PERIOD_LOADING,
  START_PAY_PERIOD_SUCCESS,
  START_PAY_PERIOD_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';

function* getEmployerPayCycleList({ body }) {
  yield put({ type: GET_EMPLOYER_PAY_CYCLE_LIST_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: 'salaryadvance/salary-manager/employer/payCycles/{employerId}',
      body,
    });
    yield put({ type: GET_EMPLOYER_PAY_CYCLE_LIST_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_EMPLOYER_PAY_CYCLE_LIST_ERROR, errorMessage: '' });
  }
}

function* getPayPeriodList({ body }) {
  yield put({ type: GET_PAY_PERIOD_LIST_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: 'salaryadvance/salary-manager/employer/employerPayPeriodsList/{employerId}',
      body,
    });
    yield put({ type: GET_PAY_PERIOD_LIST_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_PAY_PERIOD_LIST_ERROR, errorMessage: '' });
  }
}

function* refreshPayPeriodList({ body }) {
  const { employerId, payCycleId } = body;
  yield put({ type: REFRESH_PAY_PERIOD_LIST_LOADING });
  try {
    yield call(api, {
      method: 'GET',
      url: 'salaryadvance/salary-manager/employer/employerPayPeriodsList/{employerId}',
      body,
    });
    yield put({ type: REFRESH_PAY_PERIOD_LIST_SUCCESS });
    yield put({
      type: GET_PAY_PERIOD_LIST,
      body: { employerId: employerId, payCycleId: payCycleId },
    });
  } catch (error) {
    errorhandling(error);
    yield put({ type: REFRESH_PAY_PERIOD_LIST_ERROR, errorMessage: '' });
  }
}

function* startPayPeriod({ body }) {
  const { employerId, payCycleId } = body;
  delete body.employerId;
  delete body.payCycleId;
  yield put({ type: START_PAY_PERIOD_LOADING });
  try {
    yield call(api, {
      method: 'POST',
      url: 'salaryadvance/salary-manager/employer/employerPayPeriod/start/{payPeriodId}',
      body,
    });
    yield put({ type: START_PAY_PERIOD_SUCCESS });
    yield put({
      type: GET_PAY_PERIOD_LIST,
      body: { employerId: employerId, payCycleId: payCycleId },
    });
  } catch (error) {
    errorhandling(error);
    yield put({ type: START_PAY_PERIOD_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchPromotionDetailssSaga() {
  yield takeLatest(GET_EMPLOYER_PAY_CYCLE_LIST, getEmployerPayCycleList);
  yield takeLatest(GET_PAY_PERIOD_LIST, getPayPeriodList);
  yield takeLatest(REFRESH_PAY_PERIOD_LIST, refreshPayPeriodList);
  yield takeLatest(START_PAY_PERIOD, startPayPeriod);
}
