const Paths = {
  Dashboard: () => '/',

  // Employees
  Employees: () => '#/employees',
  Employee: (id) => `#/employee-details/${id}`,

  // Employers
  Employers: () => '#/',
  Employer: (id) => `#/employer-view/${id}`,

  // Advances
  Advances: () => '#/transactions',
  Advance: (id) => `#/transactions/${id}`,

  // Deduction Summary
  DeductionSummary: () => '#/deduction-summary',

  // PayPeriod
  PayPeriod: () => '#/pay-periods',

  // Payroll Data
  PayrollData: () => '#/payroll-data',
  PayrollDataFile: (id) => `#/payroll-data/${id}`,

  // users
  Users: () => '#/users',

  // settings
  Settings: () => '#/settings',
  ChangePassword: () => '#/change-password',
  MFA: () => '#/MFA',
};

export default Paths;
