import React from 'react';
import { CloseOutlined, InboxOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Image, Input, Row, Select, Switch, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createArticle, updateArticle } from '../actions';
import { MAX_ARTICLE_IMAGE_FILE_SIZE } from '../constants';
import { useArticlesContext } from '../containers/ArticlesContext';

const { Dragger } = Upload;

export default function AddArticle() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { openDrawer } = useArticlesContext();

  // Selectors for categories and selected employer
  const selectedEmployer = useSelector((state) => state.shared.selectedEmployer);
  const { data: categories } = useSelector((state) => state.library.categories);
  const { data: articles, action_loading } = useSelector((state) => state.library.articles);

  // State for file upload
  const [imageFile, setImageFile] = React.useState(null);
  const [previewImage, setPreviewImage] = React.useState(null);

  // Drawer open state
  const isOpen = React.useMemo(
    () => openDrawer.value.key === 'new-article' || openDrawer.value.key === 'edit-article',
    [openDrawer.value]
  );
  const isEditMode = openDrawer.value.key === 'edit-article';
  const drawerData = openDrawer.value.data;

  React.useEffect(() => {
    if (isEditMode) {
      const { title, link, isPublished, image, categories } = drawerData;
      const categoryIds = categories.map(({ id }) => id);
      form.setFieldsValue({ title, link, isPublished, categoryIds });
      setPreviewImage(image);
    }
  }, [isEditMode]);

  // Transform categories for select options
  const categoryOptions = React.useMemo(() => {
    return Object.values(categories.byId).map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [categories]);

  // Close drawer handler
  const onClose = () => {
    if (action_loading) return;
    form.resetFields();
    setImageFile(null);
    setPreviewImage(null);
    openDrawer.reset();
  };

  // Submit handler
  const onSubmit = (values) => {
    // Prepare form data for multipart/form-data submission
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('link', values.link);
    formData.append('isPublished', values.isPublished);

    if (isEditMode) {
      onUpdate(values, formData);
    } else {
      onCreate(values, formData);
    }
  };

  const onCreate = (formValues, formData) => {
    formData.append('image', imageFile);
    formData.append('categoryIds', JSON.stringify(formValues.categoryIds));
    const payload = {
      employerId: selectedEmployer.value,
      article: formData,
      onSuccess: onClose,
    };
    dispatch(createArticle(payload));
  };
  const onUpdate = (formValues, formData) => {
    // the image is optional in edit
    if (imageFile) formData.append('image', imageFile);

    const categories = calculateCategoriesOrders(formValues.categoryIds);

    formData.append('categories', JSON.stringify(categories));
    const payload = {
      employerId: selectedEmployer.value,
      articleId: drawerData.id,
      article: formData,
      onSuccess: onClose,
    };

    dispatch(updateArticle(payload));
  };
  function calculateCategoriesOrders(selectedCategories) {
    const categoriesOrders = [];
    for (const categoryId of selectedCategories) {
      let newOrder = 0;
      if (!articles.byCategory[categoryId]) articles.byCategory[categoryId] = [];
      for (const { article, order } of articles.byCategory[categoryId]) {
        if (article === drawerData.id) {
          newOrder = order;
          break;
        }
        if (order >= newOrder) newOrder = order + 1;
      }
      categoriesOrders.push({ categoryId, articleOrder: newOrder });
    }
    return categoriesOrders;
  }

  // File upload configuration
  const uploadProps = {
    name: 'image',
    multiple: false,
    maxCount: 1,
    accept: 'image/jpeg, image/png',
    fileList: [],
    showUploadList: false,
    beforeUpload: (file) => {
      setImageFile(file);
      setPreviewImage(URL.createObjectURL(file));
      return false; // Prevent auto upload
    },
  };

  return (
    <Drawer
      open={isOpen}
      destroyOnClose={true}
      title={isEditMode ? 'Edit article' : 'Create a new article'}
      maskClosable={false}
      onClose={onClose}
      width={720}
      extra={
        <Button onClick={onClose} icon={<CloseOutlined />}>
          Cancel
        </Button>
      }>
      <Form
        layout="vertical"
        size="large"
        form={form}
        onFinish={onSubmit}
        disabled={action_loading}
        initialValues={{
          title: '',
          link: '',
          isPublished: true,
          categoryIds: [],
        }}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please enter article title' }]}>
              <Input placeholder="Enter article title" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="link"
              label="Article Link"
              rules={[
                {
                  required: true,
                  message: 'Please enter article link',
                  type: 'url',
                },
              ]}>
              <Input prefix={<LinkOutlined />} placeholder="https://example.com/article" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="categoryIds"
              label="Categories"
              rules={[
                {
                  required: true,
                  message: 'Please select at least one category',
                },
              ]}>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select categories"
                options={categoryOptions}
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="isPublished" label="Publish Status" valuePropName="checked">
              <Switch checkedChildren="Published" unCheckedChildren="Draft" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={previewImage ? 12 : 24}>
            <Form.Item
              name="image"
              label="Article Image"
              rules={[
                {
                  required: !isEditMode,
                  message: 'Please upload an article image',
                },
                ({}) => ({
                  // validator for file size
                  validator() {
                    // not handling the required rule
                    if (!imageFile) return Promise.resolve();
                    if (imageFile.size < MAX_ARTICLE_IMAGE_FILE_SIZE) return Promise.resolve();

                    return Promise.reject(
                      new Error('Please upload an image less than 3MB in size')
                    );
                  },
                }),
              ]}
              extra="For the best display on all devices, use an image with a 3:2 aspect ratio (e.g., 1200x800).">
              <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to upload article image</p>
                <p className="ant-upload-hint">Support for a single image upload</p>
              </Dragger>
            </Form.Item>
          </Col>
          <Col span={previewImage ? 12 : 0} align="center" style={{ paddingTop: '1.5rem' }}>
            <Image src={previewImage} style={{ maxHeight: '12rem', aspectRatio: 3 / 2 }} />
          </Col>
        </Row>

        <Row gutter={16} justify="end" style={{ marginTop: '1.5rem' }}>
          <Col span={10}>
            <Form.Item>
              <Button type="primary" block htmlType="submit" loading={action_loading}>
                {isEditMode ? 'Update ' : 'Create '} Article
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
