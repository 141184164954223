import React from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { CustomSpinner } from '../../../shared';
import { AddArticleDrawer, AddCategoryDrawer, CategoriesList } from './index';
import ArticlesSection from './ArticlesSection';

export default function Articles() {
  const { loading } = useSelector((state) => state.library.categories);

  if (loading) return <CustomSpinner loading={loading} />;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <CategoriesList />
      </Col>
      <Col span={24}>
        <ArticlesSection />
      </Col>
      <AddCategoryDrawer />
      <AddArticleDrawer />
    </Row>
  );
}
