export const GET_PROGRAMS_BY_INDUSTRY = 'GET_PROGRAMS_BY_INDUSTRY';
export const GET_PROGRAMS_BY_INDUSTRY_LOADING = 'GET_PROGRAMS_BY_INDUSTRY_LOADING';
export const GET_PROGRAMS_BY_INDUSTRY_SUCCESS = 'GET_PROGRAMS_BY_INDUSTRY_SUCCESS';
export const GET_PROGRAMS_BY_INDUSTRY_ERROR = 'GET_PROGRAMS_BY_INDUSTRY_ERROR';

export const GET_EMPLOYEES_BY_EMPLOYER = 'GET_EMPLOYEES_BY_EMPLOYER';
export const GET_EMPLOYEES_BY_EMPLOYER_LOADING = 'GET_EMPLOYEES_BY_EMPLOYER_LOADING';
export const GET_EMPLOYEES_BY_EMPLOYER_SUCCESS = 'GET_EMPLOYEES_BY_EMPLOYER_SUCCESS';
export const GET_EMPLOYEES_BY_EMPLOYER_ERROR = 'GET_EMPLOYEES_BY_EMPLOYER_ERROR';

export const GET_EMPLOYER_ADOPTION_RATE = 'GET_EMPLOYER_ADOPTION_RATE';
export const GET_EMPLOYER_ADOPTION_RATE_LOADING = 'GET_EMPLOYER_ADOPTION_RATE_LOADING';
export const GET_EMPLOYER_ADOPTION_RATE_SUCCESS = 'GET_EMPLOYER_ADOPTION_RATE_SUCCESS';
export const GET_EMPLOYER_ADOPTION_RATE_ERROR = 'GET_EMPLOYER_ADOPTION_RATE_ERROR';

export const GET_AVG_AMT_PER_USR_PER_WITHDRAWAL = 'GET_AVG_AMT_PER_USR_PER_WITHDRAWAL';
export const GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_LOADING =
  'GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_LOADING';
export const GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_SUCCESS =
  'GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_SUCCESS';
export const GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_ERROR = 'GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_ERROR';

export const GET_AVG_WITHDRAWAL_FREQ_PER_USR = 'GET_AVG_WITHDRAWAL_FREQ_PER_USR';
export const GET_AVG_WITHDRAWAL_FREQ_PER_USR_LOADING = 'GET_AVG_WITHDRAWAL_FREQ_PER_USR_LOADING';
export const GET_AVG_WITHDRAWAL_FREQ_PER_USR_SUCCESS = 'GET_AVG_WITHDRAWAL_FREQ_PER_USR_SUCCESS';
export const GET_AVG_WITHDRAWAL_FREQ_PER_USR_ERROR = 'GET_AVG_WITHDRAWAL_FREQ_PER_USR_ERROR';

export const GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL = 'GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL';
export const GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_LOADING =
  'GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_LOADING';
export const GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_SUCCESS =
  'GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_SUCCESS';
export const GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_ERROR =
  'GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_ERROR';

export const GET_EMPLOYER_DAILY_WITHDRAWAL_DATA = 'GET_EMPLOYER_DAILY_WITHDRAWAL_DATA';
export const GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_LOADING =
  'GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_LOADING';
export const GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_SUCCESS =
  'GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_SUCCESS';
export const GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_ERROR = 'GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_ERROR';

export const getTotalNoOfProgramsByIndustry = (body) => ({ type: GET_PROGRAMS_BY_INDUSTRY, body });
export const getTotalNoOfEmployeesByEmployer = (body) => ({
  type: GET_EMPLOYEES_BY_EMPLOYER,
  body,
});
export const getEmployerAdoptionRate = (body) => ({ type: GET_EMPLOYER_ADOPTION_RATE, body });
export const getAvgAmountPerUserPerWithdrawal = (body) => ({
  type: GET_AVG_AMT_PER_USR_PER_WITHDRAWAL,
  body,
});
export const getAvgWithdrawalFrequencyPerUser = (body) => ({
  type: GET_AVG_WITHDRAWAL_FREQ_PER_USR,
  body,
});
export const getPercentWithdrawalFromAvailBalance = (body) => ({
  type: GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL,
  body,
});
export const getEmployerDailyWithdrawalData = (body) => ({
  type: GET_EMPLOYER_DAILY_WITHDRAWAL_DATA,
  body,
});
