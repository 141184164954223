import _ from 'lodash';

export function toQuery(queryObj) {
  if (!queryObj || !Object.keys(queryObj).length) return '';
  const queries = [];
  Object.keys(queryObj).forEach((key) => {
    if (queryObj[key]) {
      queries.push(`${key}=${queryObj[key]}`);
    }
  });
  return `?${queries.join('&')}`;
}

export const getErrorMessage = (error, defaultMessage = 'Something went wrong!') => {
  let message;
  if (error?.response?.data?.errors) {
    message = error.response.data.errors[0]?.message;
  } else if (error instanceof Error) {
    message = error.message;
  } else if (error && typeof error === 'object' && 'message' in error) {
    message = String(error.message);
  } else if (typeof error === 'string') {
    message = error;
  } else if (error && error[0]?.message) {
    message = error[0]?.message;
  } else {
    message = defaultMessage;
  }

  return message;
};

export const capitalizeWords = (name) => {
  if (!name) return '';
  return name.split(' ').map(_.capitalize).join(' ');
};

export const isColorTransparent = (colorHexString) => {
  if (!colorHexString) return true;
  return colorHexString?.endsWith('00');
};
