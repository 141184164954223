import { FolderOpenOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import ArticlesList from './ArticlesList';

const tabList = [
  {
    key: 'categorized',
    tab: 'Categorized',
    icon: <FolderOpenOutlined />,
  },
  {
    key: 'uncategorized',
    tab: 'Uncategorized',
    icon: <UnorderedListOutlined />,
  },
];

export default function ArticlesSection() {
  const [selectedTab, setSelectedTab] = React.useState('categorized');
  const { data: articles, loading } = useSelector((state) => state.library.articles);

  const list = React.useMemo(() => {
    if (!articles || loading) return [];

    if (selectedTab === 'categorized') {
      return Object.values(articles.byId).filter(
        (article) => article.categories.length && !article.isDeleted
      );
    }
    return articles.uncategorized
      .map((id) => articles.byId[id])
      .filter((article) => !article?.isDeleted);
  }, [selectedTab, articles, loading]);

  return (
    <Card
      title="Articles"
      tabProps={{ size: 'large', type: 'card', style: { marginTop: '1rem' } }}
      tabList={tabList}
      activeTabKey={selectedTab}
      onTabChange={setSelectedTab}
      loading={loading}>
      <ArticlesList articles={list} loading={loading} />
    </Card>
  );
}
