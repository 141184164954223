export const GET_PAYPERIOD = 'GET_PAYPERIOD';
export const GET_PAYPERIOD_LOADING = 'GET_PAYPERIOD_LOADING';
export const GET_PAYPERIOD_SUCCESS = 'GET_PAYPERIOD_SUCCESS';
export const GET_PAYPERIOD_ERROR = 'GET_PAYPERIOD_ERROR';

export const UPSERT_PAYPERIOD = 'UPSERT_PAYPERIOD';
export const UPSERT_PAYPERIOD_LOADING = 'UPSERT_PAYPERIOD_LOADING';
export const UPSERT_PAYPERIOD_SUCCESS = 'UPSERT_PAYPERIOD_SUCCESS';
export const UPSERT_PAYPERIOD_ERROR = 'UPSERT_PAYPERIOD_ERROR';

export const DELETE_PAYPERIOD = 'DELETE_PAYPERIOD';
export const DELETE_PAYPERIOD_LOADING = 'DELETE_PAYPERIOD_LOADING';
export const DELETE_PAYPERIOD_SUCCESS = 'DELETE_PAYPERIOD_SUCCESS';
export const DELETE_PAYPERIOD_ERROR = 'DELETE_PAYPERIOD_ERROR';

export const CLOSE_PAYPERIOD = 'CLOSE_PAYPERIOD';
export const CLOSE_PAYPERIOD_LOADING = 'CLOSE_PAYPERIOD_LOADING';
export const CLOSE_PAYPERIOD_SUCCESS = 'CLOSE_PAYPERIOD_SUCCESS';
export const CLOSE_PAYPERIOD_ERROR = 'CLOSE_PAYPERIOD_ERROR';

export const getPayPeriod = (body) => ({ type: GET_PAYPERIOD, body });
export const upsertPayPeriod = (body) => ({ type: UPSERT_PAYPERIOD, body });
export const deletePayPeriod = (body) => ({ type: DELETE_PAYPERIOD, body });
export const closePayPeriod = (body) => ({ type: CLOSE_PAYPERIOD, body });
