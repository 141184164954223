export const LOGIN = 'LOGIN';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const INVITE = 'INVITE';
export const INVITE_LOADING = 'INVITE_LOADING';
export const INVITE_SUCCESS = 'INVITE_SUCCESS';
export const REFRESH_INVITE = 'REFRESH_INVITE';
export const INVITE_ERROR = 'INVITE_ERROR';

export const UPDATE_USER_ATTRIBUTES = 'UPDATE_USER_ATTRIBUTES';
export const UPDATE_USER_ATTRIBUTES_LOADING = 'UPDATE_USER_ATTRIBUTES_LOADING';
export const UPDATE_USER_ATTRIBUTES_SUCCESS = 'UPDATE_USER_ATTRIBUTES_SUCCESS';
export const REFRESH_UPDATE_USER_ATTRIBUTES = 'REFRESH_UPDATE_USER_ATTRIBUTES';
export const UPDATE_USER_ATTRIBUTES_ERROR = 'UPDATE_USER_ATTRIBUTES_ERROR';

export const LOGOUT = 'LOGOUT';

export const SAVE_USER_DETAILS = 'SAVE_USER_DETAILS';

export const AUTH_CHALLENGE_SET = 'AUTH_CHALLENGE_SET';
export const AUTH_CHALLENGE_CLEAR = 'AUTH_CHALLENGE_CLEAR';

export const login = (body) => ({ type: LOGIN, body });
export const invite = (body) => ({ type: INVITE, body });
export const refreshInvite = () => ({ type: REFRESH_INVITE });
export const logout = (body) => ({ type: LOGOUT, body });
export const updateUserAttributes = (body) => ({ type: UPDATE_USER_ATTRIBUTES, body });

export const clearChallenges = () => ({ type: AUTH_CHALLENGE_CLEAR });
