import React from 'react';
import { Card, Col, Form, Row, Slider } from 'antd';
import { sizesFields } from '../constants';
import { FormItemLabel } from './index';

export default function SizesSection() {
  return (
    <Card title="Sizes Configuration">
      <Row gutter={[32, 16]}>
        {sizesFields.map(({ path, name, description, inputProps = {} }) => (
          <Col span={8} key={path.join()}>
            <Form.Item name={path} label={<FormItemLabel label={name} description={description} />}>
              <Slider {...inputProps} />
            </Form.Item>
          </Col>
        ))}
      </Row>
    </Card>
  );
}
