import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_EMPLOYERS,
  GET_EMPLOYERS_LOADING,
  GET_EMPLOYERS_SUCCESS,
  GET_EMPLOYERS_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';

function* getEmployers({ body }) {
  yield put({ type: GET_EMPLOYERS_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.employers.get_employers({}),
      body,
    });
    yield put({ type: GET_EMPLOYERS_SUCCESS, data: { employers: data } });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_EMPLOYERS_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployerSaga() {
  yield takeLatest(GET_EMPLOYERS, getEmployers);
}
