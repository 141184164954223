export const GET_MFA_STATUS = 'GET_MFA_STATUS';
export const GET_MFA_STATUS_LOADING = 'GET_MFA_STATUS_LOADING';
export const GET_MFA_STATUS_SUCCESS = 'GET_MFA_STATUS_SUCCESS';
export const GET_MFA_STATUS_ERROR = 'GET_MFA_STATUS_ERROR';

export const ASSOCIATE_MFA = 'ASSOCIATE_MFA';
export const ASSOCIATE_MFA_LOADING = 'ASSOCIATE_MFA_LOADING';
export const ASSOCIATE_MFA_SUCCESS = 'ASSOCIATE_MFA_SUCCESS';
export const ASSOCIATE_MFA_ERROR = 'ASSOCIATE_MFA_ERROR';

export const ENABLE_MFA = 'ENABLE_MFA';
export const ENABLE_MFA_LOADING = 'ENABLE_MFA_LOADING';
export const ENABLE_MFA_SUCCESS = 'ENABLE_MFA_SUCCESS';
export const ENABLE_MFA_ERROR = 'ENABLE_MFA_ERROR';

export const DISABLE_MFA = 'DISABLE_MFA';
export const DISABLE_MFA_LOADING = 'DISABLE_MFA_LOADING';
export const DISABLE_MFA_SUCCESS = 'DISABLE_MFA_SUCCESS';
export const DISABLE_MFA_ERROR = 'DISABLE_MFA_ERROR';

export const CLEAR_MFA_DATA = 'CLEAR_MFA_DATA';

export const getMFAStatus = (body) => ({ type: GET_MFA_STATUS, body });
export const associateMFA = (body) => ({ type: ASSOCIATE_MFA, body });
export const enableMFA = (body) => ({ type: ENABLE_MFA, body });
export const disableMFA = (body) => ({ type: DISABLE_MFA, body });
