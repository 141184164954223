import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const CustomDatePicker = ({ onChange, placeholder, value, disabledDate = false, disabled }) => {
  return (
    <DatePicker
      disabledDate={disabledDate}
      format="DD/MM/YYYY"
      value={value ? dayjs(value) : ''}
      placeholder={placeholder}
      size={'large'}
      disabled={disabled || false}
      style={{ width: '100%', height: 40 }}
      onChange={(date) => onChange(date)}
    />
  );
};

export default CustomDatePicker;
