import { combineReducers } from 'redux';
import {
  SET_SELECTED_EMPLOYEE,
  GET_EMPLOYEES_LOADING,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_ERROR,
  RESET_EMPLOYEES,
  GET_EMPLOYEE_LOADING,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_ERROR,
  CREATE_EMPLOYEE_LOADING,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_ERROR,
  UPDATE_EMPLOYEE_LOADING,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_ERROR,
  RESET_EMPLOYEE,
} from './actions';

const initialStateObject = {
  loading: false,
  data: {},
  error: '',
};

const selectedEmployee = (state, action) => {
  if (typeof state === 'undefined') {
    return {};
  }
  switch (action.type) {
    case SET_SELECTED_EMPLOYEE: {
      return action.data;
    }
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  data: {},
  error: '',
};

const employees = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case GET_EMPLOYEES_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_EMPLOYEES_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_EMPLOYEES_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case RESET_EMPLOYEES: {
      return initialState;
    }
    default:
      return state;
  }
};

const employee = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_EMPLOYEE_LOADING:
    case CREATE_EMPLOYEE_LOADING:
    case UPDATE_EMPLOYEE_LOADING: {
      return {
        loading: true,
        error: '',
        ...state,
      };
    }

    case GET_EMPLOYEE_SUCCESS:
    case UPDATE_EMPLOYEE_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case CREATE_EMPLOYEE_SUCCESS: {
      return {
        loading: false,
        data: {},
        error: '',
      };
    }
    case GET_EMPLOYEE_ERROR:
    case CREATE_EMPLOYEE_ERROR:
    case UPDATE_EMPLOYEE_ERROR: {
      return {
        loading: false,
        error: action.errorMessage,
        ...state,
      };
    }
    case RESET_EMPLOYEE: {
      return initialStateObject;
    }
    default:
      return state;
  }
};

export default combineReducers({
  selectedEmployee,
  employees,
  employee,
});
