import React from 'react';

const initialState = { selectedScreen: 'ALL', openDrawer: { key: null, data: null } };

const ArticlesContext = React.createContext({
  selectedScreen: {
    value: initialState.selectedScreen,
    set: () => {},
  },
  openDrawer: {
    value: { key: null, data: null },
    set: () => {},
    reset: () => {},
  },
});

// hook for simple usage
const useArticlesContext = () => React.useContext(ArticlesContext);

function ArticlesProvider({ children }) {
  const [selectedScreen, setSelectedScreen] = React.useState(initialState.selectedScreen);
  const [openDrawer, setOpenDrawer] = React.useState(initialState.openDrawer);
  return (
    <ArticlesContext.Provider
      value={{
        selectedScreen: { value: selectedScreen, set: setSelectedScreen },
        openDrawer: {
          value: openDrawer,
          set: setOpenDrawer,
          reset: () => setOpenDrawer(initialState.openDrawer),
        },
      }}
      children={children}
    />
  );
}

export { useArticlesContext, ArticlesProvider, ArticlesContext };
