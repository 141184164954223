import { combineReducers } from 'redux';
import {
  GET_PAY_PERIODS_LOADING,
  GET_PAY_PERIODS_SUCCESS,
  GET_PAY_PERIODS_ERROR,
  GET_DEDUCTION_SUMMARY_LOADING,
  GET_DEDUCTION_SUMMARY_SUCCESS,
  GET_DEDUCTION_SUMMARY_ERROR,

  // GET_DEDUCTION_SUMMARY_FILE_LOADING,
  // GET_DEDUCTION_SUMMARY_FILE_SUCCESS,
  // GET_DEDUCTION_SUMMARY_FILE_ERROR
} from './actions';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

const initialStateList = {
  loading: false,
  list: [],
  error: '',
};

const payPeriods = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateList;
  }
  switch (action.type) {
    case GET_PAY_PERIODS_LOADING: {
      return {
        loading: true,
        list: [],
        error: '',
      };
    }
    case GET_PAY_PERIODS_SUCCESS: {
      return {
        loading: false,
        list: action.data,
        error: '',
      };
    }
    case GET_PAY_PERIODS_ERROR: {
      return {
        loading: false,
        list: [],
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const deductionSummary = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case GET_DEDUCTION_SUMMARY_LOADING: {
      return {
        loading: true,
        data: [],
        error: '',
      };
    }
    case GET_DEDUCTION_SUMMARY_SUCCESS: {
      console.log({ GET_DEDUCTION_SUMMARY_SUCCESS: action.data });
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_DEDUCTION_SUMMARY_ERROR: {
      return {
        loading: false,
        data: [],
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

// const deductionSummaryFile = (state, action) => {
//     if (typeof state === 'undefined') {
//         return initialState
//     }
//     switch (action.type) {
//         case GET_DEDUCTION_SUMMARY_FILE_LOADING: {
//             return {
//                 loading: true,
//                 data: {},
//                 error: ""
//             }
//         }
//         case GET_DEDUCTION_SUMMARY_FILE_SUCCESS: {
//             return {
//                 loading: false,
//                 data: action.data,
//                 error: ""
//             }
//         }
//         case GET_DEDUCTION_SUMMARY_FILE_ERROR: {
//             return {
//                 loading: false,
//                 data: {},
//                 error: action.errorMessage
//             }
//         }
//         default:
//             return state
//     }
// };

export default combineReducers({
  payPeriods,
  deductionSummary,
  // deductionSummaryFile,
});
