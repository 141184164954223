import React from 'react';
import { Table } from 'antd';
import { CustomInput } from '../../..';
import { SearchOutlined } from '@ant-design/icons';

const CustomTable = ({
  columns,
  data,
  size,
  searchText,
  bordered,
  total,
  position,
  handlePageChange,
  handleSearchChange,
  searchable,
  currentPageNumber,
  loading,
  rowSelection,
  className,
  rowsPerPage = 20,
}) => {
  return (
    <Table
      className={className}
      scroll={{ x: 'max-content' }}
      rowSelection={rowSelection}
      bordered={bordered || false}
      columns={columns}
      dataSource={(data || []).map((item, index) => ({ key: index, ...item }))}
      size={size || 'middle'}
      loading={loading || false}
      title={() =>
        searchable ? (
          <CustomInput
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(value) => handleSearchChange(value)}
            placeholder={'Search'}
            style={{ width: '30%' }}
          />
        ) : null
      }
      pagination={{
        showSizeChanger: false,
        pageSize: rowsPerPage,
        total: total || data.length,
        position: position || ['bottomCenter'],
        hideOnSinglePage: true,
        onChange: handlePageChange,
        current: currentPageNumber || 1,
      }}
    />
  );
};

export default CustomTable;
