import { centerCrop, makeAspectCrop } from 'react-image-crop';

const TO_RADIANS = Math.PI / 180;

/**
 * Create a center crop object with the given aspect ratio and crop width
 * relative to the media width and height
 * @param {number} mediaWidth - width of the media
 * @param {number} mediaHeight - height of the media
 * @param {number} aspect - aspect ratio of the crop
 * @param {number} corpWidth - width of the crop
 * @returns {Object} crop object
 */
export function centerAspectCrop(mediaWidth, mediaHeight, aspect, corpWidth) {
  const corpHeight = corpWidth / aspect;
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        height: corpHeight,
        width: corpWidth,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

/**
 * Create an image from the cropped area of the given image
 * @param {{image: HTMLImageElement, crop: Object, rotate: number, scale: number, backgroundColor: string}} params - object containing image and crop parameters
 * @returns {Promise<Blob>} promise that resolves to a blob of the cropped area as a PNG image
 */
export const createImage = async ({ image, crop, rotate, scale, backgroundColor }) => {
  const canvas = document.createElement('canvas');
  canvas.style.backgroundColor = backgroundColor;

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('No 2d context');
  }

  const { naturalWidth, naturalHeight } = image;
  const scaleX = naturalWidth / image.width;
  const scaleY = naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = naturalWidth / 2;
  const centerY = naturalHeight / 2;

  ctx.save();
  ctx.fillStyle = backgroundColor;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // Rotate around the origin
  ctx.rotate(rotateRads);
  // Scale the image
  ctx.scale(scale, scale);
  // Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(image, 0, 0, naturalWidth, naturalHeight, 0, 0, naturalWidth, naturalHeight);

  ctx.restore();
  return await new Promise((resolve) => canvas.toBlob(resolve, 'image/png', 1));
};

// Helper function to scale dimensions while preserving aspect ratio
export const scaleDimensions = ({ width, height, maxWidth, maxHeight }) => {
  const aspectRatio = width / height;
  let scaledWidth = width;
  let scaledHeight = height;

  if (scaledWidth > maxWidth) {
    scaledWidth = maxWidth;
    scaledHeight = scaledWidth / aspectRatio;
  }
  if (scaledHeight > maxHeight) {
    scaledHeight = maxHeight;
    scaledWidth = scaledHeight * aspectRatio;
  }
  return { width: scaledWidth, height: scaledHeight };
};
