import React from 'react';
import { Button } from 'antd';

const CustomButton = ({
  label,
  type,
  handleClick,
  style,
  className,
  icon,
  htmlType,
  disabled,
  loading,
}) => (
  <Button
    loading={loading}
    icon={icon}
    htmlType={htmlType || 'button'}
    type={type}
    disabled={disabled || false || loading}
    style={style}
    className={className}
    onClick={handleClick}>
    {label}
  </Button>
);

export default CustomButton;
