import React from 'react';
import { Select } from 'antd';
import { isObject } from 'lodash';

const { Option, OptGroup } = Select;

const CustomSelect = ({ onChange, placeholder, options1, options2, value, disabled, mode }) => {
  return (
    <Select
      size={'large'}
      value={value}
      disabled={disabled || false}
      placeholder={placeholder}
      style={{ width: '100%', fontSize: 14 }}
      onChange={onChange}
      mode={mode || 'none'}>
      <OptGroup label="LATEST">
        {options1.map((item, index) =>
          isObject(item) ? (
            <Option key={index} value={item.value}>
              {item.label}
            </Option>
          ) : (
            <Option value={item}>{item}</Option>
          )
        )}
      </OptGroup>
      {options2.length > 0 && (
        <OptGroup label="HISTORY">
          {options2.map((item, index) =>
            isObject(item) ? (
              <Option key={index} value={item.value}>
                {item.label}
              </Option>
            ) : (
              <Option value={item}>{item}</Option>
            )
          )}
        </OptGroup>
      )}
    </Select>
  );
};

export default CustomSelect;
