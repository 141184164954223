export const chartColors = [
  '#284683',
  '#34b99b',
  '#dd1e78',
  '#CC9933',
  '#006666',
  '#3399FF',
  '#993300',
  '#CCCC99',
  '#666666',
  '#FFCC66',
  '#6699CC',
  '#663366',
  '#9999CC',
  '#CCCCCC',
  '#669999',
  '#CCCC66',
  '#CC6600',
  '#9999FF',
  '#0066CC',
  '#99CCCC',
  '#999999',
  '#FFCC00',
  '#009999',
  '#99CC33',
  '#FF9900',
  '#999966',
  '#66CCCC',
  '#339966',
  '#CCCC33',
  '#003f5c',
  '#665191',
  '#a05195',
  '#d45087',
  '#2f4b7c',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#EF6F6C',
  '#465775',
  '#56E39F',
  '#59C9A5',
  '#5B6C5D',
  '#0A2342',
  '#2CA58D',
  '#84BC9C',
  '#CBA328',
  '#F46197',
  '#DBCFB0',
  '#545775',
];

export const EmployerAndIndustryList = [
  { label: 'All Employers', value: 'All Employers' },
  { label: 'All Industry', value: 'All Industry' },
  { label: 'Industry 1', value: 'Industry 1' },
  { label: 'Industry 2', value: 'Industry 2' },
  { label: 'Industry 3', value: 'Industry 3' },
  { label: 'Employer 1', value: 'Employer 1' },
  { label: 'Employer 2', value: 'Employer 2' },
];

export const mySnapshotFilterBy = [
  { label: 'By Employer', value: 'By Employer' },
  { label: 'By Industry', value: 'By Industry' },
];

export const AVG_AMT_PER_USR_PER_WITHDRAWAL_WIDGET_KEY = 'avgAmountPerUserPerWithdrawal';
export const AVG_WITHDRAWAL_FREQ_PER_USR_WIDGET_KEY = 'avgWithdrawalFrequencyPerUser';
export const EMPLOYER_DAILY_WITHDRAWAL_DATA_WIDGET_KEY = 'employerDailyWithdrawalData';
export const EMPLOYER_ADOPTION_RATE_WIDGET_KEY = 'employerAdoptionRate';
export const PERCENT_WITHDRAWAL_FROM_AVAIL_BALE_WIDGET_KEY = 'percentWithdrawalFromAvailBalance';

export const START_DATE_KEY = 'startDate';
export const END_DATE_KEY = 'endDate';

export const DoughnutChartOptions = {
  legend: {
    display: true,
    position: 'bottom',
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  responsive: true,
  maintainAspectRatio: true,
};
