import React from 'react';
import { Input } from 'antd';

const CustomInput = ({
  autoComplete,
  placeholder,
  onChange,
  value,
  type,
  addonBefore,
  addonAfter,
  prefix,
  suffix,
  style,
  disabled,
  id,
  defaultValue,
}) => (
  <Input
    id={id}
    autoComplete={autoComplete}
    prefix={prefix}
    suffix={suffix}
    addonBefore={addonBefore || null}
    addonAfter={addonAfter || null}
    size={'large'}
    type={type || 'text'}
    value={value}
    placeholder={placeholder}
    defaultValue={defaultValue}
    disabled={disabled || false}
    onChange={(e) => onChange(e.currentTarget.value)}
    style={style}
  />
);

export default CustomInput;
