import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_USERS,
  GET_USERS_LOADING,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_USER_BY_ID,
  GET_USER_BY_ID_LOADING,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_ERROR,
  ADD_USER,
  ADD_USER_LOADING,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  ENABLE_DISABLE_USER,
  ENABLE_DISABLE_USER_LOADING,
  ENABLE_DISABLE_USER_SUCCESS,
  ENABLE_DISABLE_USER_ERROR,

  // invite users
  GET_INVITATION_USERS,
  GET_INVITATION_USERS_LOADING,
  GET_INVITATION_USERS_SUCCESS,
  GET_INVITATION_USERS_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';

function* getUsers({ body }) {
  yield put({ type: GET_USERS_LOADING });
  try {
    const query = {
      number_page: body.page || 1,
      status: body.search,
      employer_id: body.employerId,
      item_per_page: 10,
    };
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.employers.get_employer_users(query),
    });
    yield put({ type: GET_USERS_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_USERS_ERROR, errorMessage: '' });
  }
}

function* getInvitations({ body }) {
  yield put({ type: GET_INVITATION_USERS_LOADING });
  try {
    const query = {
      page_number: body.page_number || 1,
      status: body.status,
      role: body.role,
      employer_id: body.employerId,
      item_per_page: 10,
    };
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.employers.get_employer_invitations_users(query),
    });
    yield put({ type: GET_INVITATION_USERS_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_INVITATION_USERS_ERROR, errorMessage: '' });
  }
}

function* getUserById({ body }) {
  yield put({ type: GET_USER_BY_ID_LOADING });
  try {
    console.log({ body });
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.employers.get_employer_user(body.id),
      // body
    });
    yield put({ type: GET_USER_BY_ID_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_USER_BY_ID_ERROR, errorMessage: '' });
  }
}

function* createUser({ body }) {
  const { employerId } = body;
  yield put({ type: ADD_USER_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.employers.create_employer_user({
        employer_id: body.employerId,
      }),
      body,
    });
    yield put({ type: ADD_USER_SUCCESS, data: data });
    yield put({ type: GET_INVITATION_USERS, body: { employerId } });
  } catch (error) {
    errorhandling(error);
    yield put({ type: ADD_USER_ERROR, errorMessage: '' });
  }
}

function* updateUserById({ body }) {
  const { employerId } = body;
  yield put({ type: UPDATE_USER_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'PUT',
      url: apiConfig.employers.update_employer_user(body.id),
      body,
    });
    yield put({ type: UPDATE_USER_SUCCESS, data: data });
    yield put({ type: GET_USERS, body: { employerId } });
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_USER_ERROR, errorMessage: '' });
  }
}

function* updateStatus({ body }) {
  const { employerId } = body;
  delete body.employerId;
  yield put({ type: ENABLE_DISABLE_USER_LOADING });
  try {
    const endpoint = body.active
      ? apiConfig.employers.disable_employer_user({
          employer_user_id: body.id,
        })
      : apiConfig.employers.enable_employer_user({
          employer_user_id: body.id,
        });
    const { data } = yield call(api, {
      method: 'POST',
      url: endpoint,
      body,
    });
    yield put({ type: ENABLE_DISABLE_USER_SUCCESS, data: data });
    yield put({ type: GET_USERS, body: { employerId } });
  } catch (error) {
    errorhandling(error);
    yield put({ type: ENABLE_DISABLE_USER_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchUsersSaga() {
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(GET_INVITATION_USERS, getInvitations);
  yield takeLatest(GET_USER_BY_ID, getUserById);
  yield takeLatest(ADD_USER, createUser);
  yield takeLatest(UPDATE_USER, updateUserById);
  yield takeLatest(ENABLE_DISABLE_USER, updateStatus);
}
