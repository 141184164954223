import React from 'react';
import { Modal } from 'antd';

const CustomModal = ({
  title,
  content,
  buttons,
  visible,
  closable,
  footer,
  width,
  handleClose,
}) => {
  const handleOk = () => {};

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Modal
      title={title}
      width={width || 520}
      closable={closable || false}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footer || null}
      maskClosable={false}>
      {content}
      {buttons}
    </Modal>
  );
};

export default CustomModal;
