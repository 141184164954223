import { combineReducers } from 'redux';
import {
  GET_PAYPERIOD_LOADING,
  GET_PAYPERIOD_SUCCESS,
  GET_PAYPERIOD_ERROR,
  UPSERT_PAYPERIOD_LOADING,
  UPSERT_PAYPERIOD_ERROR,
  DELETE_PAYPERIOD_LOADING,
  DELETE_PAYPERIOD_ERROR,
  DELETE_PAYPERIOD_SUCCESS,
  CLOSE_PAYPERIOD_LOADING,
  CLOSE_PAYPERIOD_ERROR,
  CLOSE_PAYPERIOD_SUCCESS,
} from './actions';

const initialStateList = {
  loading: false,
  data: [],
  error: '',
  actionLoading: false,
};

const payPeriods = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateList;
  }
  switch (action.type) {
    case GET_PAYPERIOD_LOADING: {
      return {
        loading: true,
        data: [],
        error: '',
        actionLoading: false,
      };
    }
    case GET_PAYPERIOD_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
        actionLoading: false,
      };
    }
    case GET_PAYPERIOD_ERROR: {
      return {
        loading: false,
        data: [],
        error: action.errorMessage,
        actionLoading: false,
      };
    }
    case UPSERT_PAYPERIOD_LOADING: {
      return {
        ...state,
        actionLoading: true,
      };
    }
    case UPSERT_PAYPERIOD_ERROR: {
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
        actionLoading: false,
      };
    }
    case DELETE_PAYPERIOD_LOADING: {
      return {
        ...state,
        actionLoading: true,
      };
    }

    case DELETE_PAYPERIOD_ERROR: {
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
        actionLoading: false,
      };
    }
    case DELETE_PAYPERIOD_SUCCESS: {
      return {
        ...state,
        actionLoading: false,
      };
    }
    case CLOSE_PAYPERIOD_LOADING: {
      return {
        ...state,
        actionLoading: true,
      };
    }
    case CLOSE_PAYPERIOD_ERROR: {
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
        actionLoading: false,
      };
    }
    case CLOSE_PAYPERIOD_SUCCESS: {
      return {
        ...state,
        actionLoading: false,
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  payPeriods,
});
