export const GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL = 'GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL';
export const GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_LOADING =
  'GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_LOADING';
export const GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_SUCCESS =
  'GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_SUCCESS';
export const GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_ERROR =
  'GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_ERROR';

export const GET_EMPLOYER_DASHBOARD_TOTAL_USERS = 'GET_EMPLOYER_DASHBOARD_TOTAL_USERS';
export const GET_EMPLOYER_DASHBOARD_TOTAL_USERS_LOADING =
  'GET_EMPLOYER_DASHBOARD_TOTAL_USERS_LOADING';
export const GET_EMPLOYER_DASHBOARD_TOTAL_USERS_SUCCESS =
  'GET_EMPLOYER_DASHBOARD_TOTAL_USERS_SUCCESS';
export const GET_EMPLOYER_DASHBOARD_TOTAL_USERS_ERROR = 'GET_EMPLOYER_DASHBOARD_TOTAL_USERS_ERROR';

export const GET_EMPLOYER_DASHBOARD_APP_USAGE = 'GET_EMPLOYER_DASHBOARD_APP_USAGE';
export const GET_EMPLOYER_DASHBOARD_APP_USAGE_LOADING = 'GET_EMPLOYER_DASHBOARD_APP_USAGE_LOADING';
export const GET_EMPLOYER_DASHBOARD_APP_USAGE_SUCCESS = 'GET_EMPLOYER_DASHBOARD_APP_USAGE_SUCCESS';
export const GET_EMPLOYER_DASHBOARD_APP_USAGE_ERROR = 'GET_EMPLOYER_DASHBOARD_APP_USAGE_ERROR';

export const GET_EMPLOYER_DASHBOARD_TOP_USERS = 'GET_EMPLOYER_DASHBOARD_TOP_USERS';
export const GET_EMPLOYER_DASHBOARD_TOP_USERS_LOADING = 'GET_EMPLOYER_DASHBOARD_TOP_USERS_LOADING';
export const GET_EMPLOYER_DASHBOARD_TOP_USERS_SUCCESS = 'GET_EMPLOYER_DASHBOARD_TOP_USERS_SUCCESS';
export const GET_EMPLOYER_DASHBOARD_TOP_USERS_ERROR = 'GET_EMPLOYER_DASHBOARD_TOP_USERS_ERROR';

export const GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS = 'GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS';
export const GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_LOADING =
  'GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_LOADING';
export const GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_SUCCESS =
  'GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_SUCCESS';
export const GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_ERROR =
  'GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_ERROR';

export const getTotalWithdrawals = (body) => ({
  type: GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL,
  body,
});
export const getTotalUsers = (body) => ({ type: GET_EMPLOYER_DASHBOARD_TOTAL_USERS, body });
export const getAppUsage = (body) => ({ type: GET_EMPLOYER_DASHBOARD_APP_USAGE, body });
export const getTopUsers = (body) => ({ type: GET_EMPLOYER_DASHBOARD_TOP_USERS, body });
export const getAppUsuageUsers = (body) => ({ type: GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS, body });
