import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_DEFAULT_SETTINGS,
  GET_DEFAULT_SETTINGS_LOADING,
  GET_DEFAULT_SETTINGS_SUCCESS,
  GET_DEFAULT_SETTINGS_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';

function* getDefaultSettings({ body }) {
  yield put({ type: GET_DEFAULT_SETTINGS_LOADING });
  try {
    // const { data } = yield call(api, {
    //     method: 'GET',
    //     url: apiConfig.payroll.get_default_settings(),
    // });

    yield put({
      type: GET_DEFAULT_SETTINGS_SUCCESS,
      data: {},
    });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_DEFAULT_SETTINGS_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchSettingsSaga() {
  yield takeLatest(GET_DEFAULT_SETTINGS, getDefaultSettings);
}
