import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_INVOICES,
  GET_INVOICES_LOADING,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';

function* getInvoices({ body }) {
  yield put({ type: GET_INVOICES_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: 'salaryadvance/salary-manager/invoiceList',
      body,
    });
    yield put({ type: GET_INVOICES_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_INVOICES_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchInvoicesSaga() {
  yield takeLatest(GET_INVOICES, getInvoices);
}
