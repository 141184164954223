import { combineReducers } from 'redux';
import {
  GET_EMPLOYEE_BY_ID_LOADING,
  GET_EMPLOYEE_BY_ID_SUCCESS,
  GET_EMPLOYEE_BY_ID_ERROR,
  GET_EMPLOYEE_PAYTIME_INFORMATION_LOADING,
  GET_EMPLOYEE_PAYTIME_INFORMATION_SUCCESS,
  GET_EMPLOYEE_PAYTIME_INFORMATION_ERROR,
  UPDATE_EMPLOYEE_STATUS_LOADING,
  UPDATE_EMPLOYEE_STATUS_SUCCESS,
  UPDATE_EMPLOYEE_STATUS_ERROR,
} from './actions';

const initialState = {
  loading: false,
  data: {},
  error: '',
};
const employee = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case GET_EMPLOYEE_BY_ID_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_EMPLOYEE_BY_ID_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_EMPLOYEE_BY_ID_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const paytimeInformation = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case GET_EMPLOYEE_PAYTIME_INFORMATION_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_EMPLOYEE_PAYTIME_INFORMATION_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_EMPLOYEE_PAYTIME_INFORMATION_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const employeeUpdates = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case UPDATE_EMPLOYEE_STATUS_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case UPDATE_EMPLOYEE_STATUS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case UPDATE_EMPLOYEE_STATUS_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  employee,
  employeeUpdates,
  paytimeInformation,
});
