import { combineReducers } from 'redux';
import {
  GET_PAYROLL_FILE_BY_ID_LOADING,
  GET_PAYROLL_FILE_BY_ID_SUCCESS,
  GET_PAYROLL_FILE_BY_ID_ERROR,
  GET_PAYROLL_FILE_RECORDS_LOADING,
  GET_PAYROLL_FILE_RECORDS_SUCCESS,
  GET_PAYROLL_FILE_RECORDS_ERROR,
  UPDATE_RECORDS_LOADING,
  UPDATE_RECORDS_SUCCESS,
  UPDATE_RECORDS_ERROR,
} from './actions';

const initialState = {
  loading: false,
  data: {},
  error: '',
};

const initialRecordsState = {
  loading: false,
  data: [],
  error: '',
};

const payrollFile = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case GET_PAYROLL_FILE_BY_ID_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_PAYROLL_FILE_BY_ID_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_PAYROLL_FILE_BY_ID_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const payrollFileRecords = (state, action) => {
  if (typeof state === 'undefined') {
    return initialRecordsState;
  }
  switch (action.type) {
    case GET_PAYROLL_FILE_RECORDS_LOADING: {
      return {
        loading: true,
        data: [],
        error: '',
      };
    }
    case GET_PAYROLL_FILE_RECORDS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_PAYROLL_FILE_RECORDS_ERROR: {
      return {
        loading: false,
        data: [],
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const updatedRecord = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case UPDATE_RECORDS_LOADING: {
      return {
        loading: true,
        data: false,
        error: '',
      };
    }
    case UPDATE_RECORDS_SUCCESS: {
      return {
        loading: false,
        data: true,
        error: '',
      };
    }
    case UPDATE_RECORDS_ERROR: {
      return {
        loading: false,
        data: false,
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  payrollFile,
  payrollFileRecords,
  updatedRecord,
});
