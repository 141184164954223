export const GET_PROMOTION_BY_ID = 'GET_PROMOTION_BY_ID';
export const GET_PROMOTION_BY_ID_LOADING = 'GET_PROMOTION_BY_ID_LOADING';
export const GET_PROMOTION_BY_ID_SUCCESS = 'GET_PROMOTION_BY_ID_SUCCESS';
export const GET_PROMOTION_BY_ID_ERROR = 'GET_PROMOTION_BY_ID_ERROR';

export const GET_ALL_PROMOTION_CODES = 'GET_ALL_PROMOTION_CODES';
export const GET_ALL_PROMOTION_CODES_LOADING = 'GET_ALL_PROMOTION_CODES_LOADING';
export const GET_ALL_PROMOTION_CODES_SUCCESS = 'GET_ALL_PROMOTION_CODES_SUCCESS';
export const GET_ALL_PROMOTION_CODES_ERROR = 'GET_ALL_PROMOTION_CODES_ERROR';

export const UPSERT_EMPLOYER_PROMOTION = 'UPSERT_EMPLOYER_PROMOTION';
export const UPSERT_EMPLOYER_PROMOTION_LOADING = 'UPSERT_EMPLOYER_PROMOTION_LOADING';
export const UPSERT_EMPLOYER_PROMOTION_SUCCESS = 'UPSERT_EMPLOYER_PROMOTION_SUCCESS';
export const UPSERT_EMPLOYER_PROMOTION_ERROR = 'UPSERT_EMPLOYER_PROMOTION_ERROR';

export const UPDATE_PROMOTION_STATUS = 'UPDATE_PROMOTION_STATUS';
export const UPDATE_PROMOTION_STATUS_LOADING = 'UPDATE_PROMOTION_STATUS_LOADING';
export const UPDATE_PROMOTION_STATUS_SUCCESS = 'UPDATE_PROMOTION_STATUS_SUCCESS';
export const UPDATE_PROMOTION_STATUS_ERROR = 'UPDATE_PROMOTION_STATUS_ERROR';

export const getPromotionById = (body) => ({ type: GET_PROMOTION_BY_ID, body });
export const getPromotionCodes = (body) => ({ type: GET_ALL_PROMOTION_CODES, body });
export const upsertEmployerPromotion = (body) => ({ type: UPSERT_EMPLOYER_PROMOTION, body });
export const upsertPromotionStatus = (body) => ({ type: UPDATE_PROMOTION_STATUS, body });
