const theme = {
  token: {
    colorPrimary: '#013e7b',
    colorInfo: '#013e7b',
    wireframe: true,
    borderRadius: 2,
  },
  components: {
    Button: {
      algorithm: true,
      colorPrimary: '#96c428',
    },
    Layout: {
      algorithm: true,
      bodyBg: '#f0f2f5',
      footerBg: '#ffffff',
      headerBg: '#ffffff',
      siderBg: '#ffffff',
      triggerBg: '#002140',
      headerPadding: '0',
      headerHeight: 66,
    },
    Menu: {
      algorithm: true,
      itemHoverColor: '#1890ff',
      itemSelectedBg: '#c2e2f3',
      itemSelectedColor: '#013e7b',
      activeBarWidth: 3,
      subMenuItemBorderRadius: 0,
      itemMarginInline: 0,
      itemBorderRadius: 0,
      iconSize: 20,
      itemMarginBlock: 8,
    },
    Form: {
      algorithm: true,
      verticalLabelPadding: '0px',
      itemMarginBottom: 13,
      inlineItemMarginBottom: 12,
      labelColor: '#000000d9',
    },
    Input: {
      algorithm: true,
      colorBorder: '#00000012',
    },
    Rate: {
      algorithm: true,
      colorText: '#000000',
    },
    Select: {
      algorithm: true,
      optionFontSize: 13,
      fontSize: 13,
    },
    Table: {
      algorithm: true,
      headerBg: '#444659',
      headerColor: '#ffffff',
      headerSortActiveBg: '#444659',
      headerSortHoverBg: '#444659eb',
    },
  },
};

export default theme;
