import React from 'react';
import { App } from 'antd';

export const withAntApp = (Component) => {
  return (props) => {
    const {
      message: messageInstance,
      notification: notificationInstance,
      modal: modalInstance,
    } = App.useApp();

    return (
      <Component
        {...props}
        messageInstance={messageInstance}
        notificationInstance={notificationInstance}
        modalInstance={modalInstance}
      />
    );
  };
};
