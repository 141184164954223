import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_REMINDERS,
  GET_REMINDERS_LOADING,
  GET_REMINDERS_SUCCESS,
  GET_REMINDERS_ERROR,

  CREATE_REMINDER,
  CREATE_CREATE_REMINDER_LOADING,
  CREATE_CREATE_REMINDER_SUCCESS,
  CREATE_CREATE_REMINDER_ERROR,

  UPDATE_REMINDER,
  UPDATE_REMINDER_LOADING,
  UPDATE_REMINDER_SUCCESS,
  UPDATE_REMINDER_ERROR
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';

function* getReminders({ body }) {
  yield put({ type: GET_REMINDERS_LOADING });
  try {
    const  { data }  = yield call(api, {
      method: 'GET',
      url: apiConfig.employers.get_reminder( { employer_id:body} ),
    });
    yield put({ type: GET_REMINDERS_SUCCESS, data: data || {} });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_REMINDERS_ERROR, errorMessage: '' });
  }
}



function* createReminder({ body }) {
  yield put({ type: CREATE_CREATE_REMINDER_LOADING });
  try {
    console.log({ bodyFromCREATE: body })
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.employers.create_reminder(),
      body: body,
    });
    yield put({ type: CREATE_CREATE_REMINDER_SUCCESS, data: data || {} });
  } catch (error) {
    errorhandling(error);
    yield put({ type: CREATE_CREATE_REMINDER_ERROR, errorMessage: '' });
  }
}

function* updateReminder({ body }) {
  yield put({ type: UPDATE_REMINDER_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'PUT',
      url: apiConfig.employers.update_reminder({ reminder_id: body.reminder_id }),
      body: body.reminder,
    });
    yield put({ type: UPDATE_REMINDER_SUCCESS, data: data || {} });
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_REMINDER_ERROR, errorMessage: '' });
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployerReminderSaga() {
  yield takeLatest(GET_REMINDERS, getReminders);
  yield takeLatest(CREATE_REMINDER, createReminder);
  yield takeLatest(UPDATE_REMINDER, updateReminder);
}
