import React from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const CustomUpload = ({ listType, onChange, field, multiple, fileList, beforeUpload, accept }) => {
  return (
    <Upload
      accept={accept}
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      listType={listType || 'text'}
      multiple={multiple || false}
      onChange={(value) => onChange(value)}
      fileList={fileList}
      beforeUpload={beforeUpload || (() => true)}>
      <Button icon={<UploadOutlined />}>{`Upload ${field}`}</Button>
    </Upload>
  );
};

export default CustomUpload;
