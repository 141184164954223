export const GET_PAYROLL_FILE_BY_ID = 'GET_PAYROLL_FILE_BY_ID';
export const GET_PAYROLL_FILE_BY_ID_LOADING = 'GET_PAYROLL_FILE_BY_ID_LOADING';
export const GET_PAYROLL_FILE_BY_ID_SUCCESS = 'GET_PAYROLL_FILE_BY_ID_SUCCESS';
export const GET_PAYROLL_FILE_BY_ID_ERROR = 'GET_PAYROLL_FILE_BY_ID_ERROR';

export const GET_PAYROLL_FILE_RECORDS = 'GET_PAYROLL_FILE_RECORDS';
export const GET_PAYROLL_FILE_RECORDS_LOADING = 'GET_PAYROLL_FILE_RECORDS_LOADING';
export const GET_PAYROLL_FILE_RECORDS_SUCCESS = 'GET_PAYROLL_FILE_RECORDS_SUCCESS';
export const GET_PAYROLL_FILE_RECORDS_ERROR = 'GET_PAYROLL_FILE_RECORDS_ERROR';

export const UPDATE_RECORDS = 'UPDATE_RECORDS';
export const UPDATE_RECORDS_LOADING = 'UPDATE_RECORDS_LOADING';
export const UPDATE_RECORDS_SUCCESS = 'UPDATE_RECORDS_SUCCESS';
export const UPDATE_RECORDS_ERROR = 'UPDATE_RECORDS_ERROR';

export const getPayrollFileById = (body) => ({ type: GET_PAYROLL_FILE_BY_ID, body });
export const getPayrollFileRecords = (body) => ({ type: GET_PAYROLL_FILE_RECORDS, body });
export const updateRecord = (body) => ({ type: UPDATE_RECORDS, body });
