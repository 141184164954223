import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { getEmployerFundingStatus } from '../../../shared/actions';

const EmployerBalanceAlertMessage = {
  EMPLOYER: 'Your account balance has fallen below the minimum threshold.',
  ADVANCIA_ADMIN: 'Employer account balance has fallen below the minimum threshold.',
};

export default function EmployerBalanceAlert({ userType, selectedEmployer }) {
  const dispatch = useDispatch();

  const { isLowBalance = false } = useSelector(
    (state) => state.shared?.employerFundingStatus?.data
  );

  // Get the employer funding status when the selected employer changes
  useEffect(() => {
    dispatch(getEmployerFundingStatus({ employerId: selectedEmployer?.value }));
  }, [selectedEmployer]);

  const alertMessage = EmployerBalanceAlertMessage[userType] ?? '';

  // If no alert message or no low balance, don't render
  if (!alertMessage || !isLowBalance) return <></>;

  return (
    <Alert
      type="warning"
      showIcon
      banner
      message="Notice: Balance Below Minimum Threshold"
      description={alertMessage}
      closable={{
        'aria-label': 'close',
        closeIcon: (
          <Button icon={<CloseOutlined />} iconPosition="end" variant="outlined">
            Dismiss
          </Button>
        ),
      }}
    />
  );
}
