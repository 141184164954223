export const UPDATE_EMPLOYER_THEME_CS = 'UPDATE_EMPLOYER_THEME_CS';
export const UPDATE_EMPLOYER_THEME_CS_SUCCESS = 'UPDATE_EMPLOYER_THEME_CS_SUCCESS';
export const UPDATE_EMPLOYER_THEME_CS_ERROR = 'UPDATE_EMPLOYER_THEME_CS_ERROR';

export const UPDATE_EMPLOYER_THEME_LOGO = 'UPDATE_EMPLOYER_THEME_LOGO';
export const UPDATE_EMPLOYER_THEME_LOGO_LOADING = 'UPDATE_EMPLOYER_THEME_LOGO_LOADING';
export const UPDATE_EMPLOYER_THEME_LOGO_SUCCESS = 'UPDATE_EMPLOYER_THEME_LOGO_SUCCESS';
export const UPDATE_EMPLOYER_THEME_LOGO_ERROR = 'UPDATE_EMPLOYER_THEME_LOGO_ERROR';

export const GET_EMPLOYER_THEME = 'GET_EMPLOYER_THEME';
export const GET_EMPLOYER_THEME_LOADING = 'GET_EMPLOYER_THEME_LOADING';
export const GET_EMPLOYER_THEME_SUCCESS = 'GET_EMPLOYER_THEME_SUCCESS';
export const GET_EMPLOYER_THEME_ERROR = 'GET_EMPLOYER_THEME_ERROR';

// update employer theme locally
export const SET_EMPLOYER_THEME = 'SET_EMPLOYER_THEME';

export const getEmployerTheme = (body) => ({ type: GET_EMPLOYER_THEME, body });
export const updateEmployerThemeColorScheme = (body) => ({ type: UPDATE_EMPLOYER_THEME_CS, body });
export const updateEmployerThemeLogo = (body) => ({ type: UPDATE_EMPLOYER_THEME_LOGO, body });
export const setEmployerTheme = (body) => ({ type: SET_EMPLOYER_THEME, body });

// selector for employer theme from the state
export const employerThemeSelector = (state) => {
  const employerTheme = state?.employerTheme?.employerTheme;
  return {
    loading: employerTheme?.loading,
    error: employerTheme?.error,
    data: employerTheme?.data?.theme,
  };
};
