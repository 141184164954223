import { combineReducers } from 'redux';
import {
  GET_PROGRAMS_BY_INDUSTRY_LOADING,
  GET_PROGRAMS_BY_INDUSTRY_SUCCESS,
  GET_PROGRAMS_BY_INDUSTRY_ERROR,
  GET_EMPLOYEES_BY_EMPLOYER_LOADING,
  GET_EMPLOYEES_BY_EMPLOYER_SUCCESS,
  GET_EMPLOYEES_BY_EMPLOYER_ERROR,
  GET_EMPLOYER_ADOPTION_RATE_LOADING,
  GET_EMPLOYER_ADOPTION_RATE_SUCCESS,
  GET_EMPLOYER_ADOPTION_RATE_ERROR,
  GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_LOADING,
  GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_SUCCESS,
  GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_ERROR,
  GET_AVG_WITHDRAWAL_FREQ_PER_USR_LOADING,
  GET_AVG_WITHDRAWAL_FREQ_PER_USR_SUCCESS,
  GET_AVG_WITHDRAWAL_FREQ_PER_USR_ERROR,
  GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_LOADING,
  GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_SUCCESS,
  GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_ERROR,
  GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_LOADING,
  GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_SUCCESS,
  GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_ERROR,
} from './actions';

import { chartColors } from './constants';

const initialStateObject = { loading: false, data: {}, error: '' };

const prepareIndustryDoughnutChartData = (allData) => {
  const data = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors,
      },
    ],
  };
  const { industryEmployerCounts, totalCount } = allData;
  industryEmployerCounts &&
    industryEmployerCounts.forEach((el) => {
      data.labels.push(el.industry);
      data.datasets[0].data.push(el.employerCount);
    });
  data.totalCount = totalCount;
  return data;
};

const prepareEmployeesDoughnutChartData = (allData) => {
  const data = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors,
      },
    ],
  };
  const { employerEmployeeCounts } = allData;
  employerEmployeeCounts &&
    employerEmployeeCounts.forEach((el) => {
      if (el.employeeCount > 0) {
        data.labels.push(el.employerName);
        data.datasets[0].data.push(el.employeeCount);
      }
    });
  return data;
};

const prepareEmployerDailyWithdrawalData = (allData) => {
  const { response } = allData;
  const {
    dailyWithdrawls,
    dailyAverageFrequency,
    dailyAverageWithdrawl,
    lastFifteenDaysFrequency,
    lastFifteenDaysWithdrawl,
    todayWithdrawl,
    todayWithdrawlFrequency,
    totalFrequencyYTD,
    totalWithdrawlYTD,
  } = response;
  let data = {
    dailyWithdrawlsAmount: [],
    withDrawalAmountsSnapshot: [
      { label: 'Today', amount: todayWithdrawl },
      { label: 'Total last 15 days', amount: lastFifteenDaysWithdrawl },
      { label: 'Daily Average', amount: dailyAverageWithdrawl },
      { label: 'Total YTD', amount: totalWithdrawlYTD },
    ],
    dailyWithdrawlsFreq: [],
    withDrawalFrequencySnapshot: [
      { label: 'Today', frequency: todayWithdrawlFrequency },
      { label: 'Total last 15 days', frequency: lastFifteenDaysFrequency },
      { label: 'Daily Average', frequency: dailyAverageFrequency },
      { label: 'Total YTD', frequency: totalFrequencyYTD },
    ],
    employersList: [],
  };
  if (dailyWithdrawls) {
    for (const employer in dailyWithdrawls) {
      dailyWithdrawls[employer].forEach((el) => {
        let amountData = data.dailyWithdrawlsAmount.find((x) => x.date === el.date);
        let frequencyData = data.dailyWithdrawlsFreq.find((x) => x.date === el.date);
        if (!amountData) {
          amountData = {
            date: el.date,
          };
        }
        if (!frequencyData) {
          frequencyData = {
            date: el.date,
          };
        }
        amountData[el.employerName] = el.withdrawlAmount;
        frequencyData[el.employerName] = el.withdrawlFrequency;
        if (!data.employersList.includes(el.employerName)) {
          data.employersList.push(el.employerName);
        }
        data.dailyWithdrawlsAmount = data.dailyWithdrawlsAmount.filter((a) => a.date !== el.date);
        data.dailyWithdrawlsFreq = data.dailyWithdrawlsFreq.filter((a) => a.date !== el.date);
        data.dailyWithdrawlsAmount.push(amountData);
        data.dailyWithdrawlsFreq.push(frequencyData);
      });
    }
  }
  if (data.dailyWithdrawlsAmount.length > 0) {
    data.dailyWithdrawlsAmount.sort((a, b) => new Date(b.date) - new Date(a.date));
    data.dailyWithdrawlsAmount.reverse();
  }
  if (data.dailyWithdrawlsFreq.length > 0) {
    data.dailyWithdrawlsFreq.sort((a, b) => new Date(b.date) - new Date(a.date));
    data.dailyWithdrawlsFreq.reverse();
  }
  return data;
};

const programsByIndustry = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_PROGRAMS_BY_INDUSTRY_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_PROGRAMS_BY_INDUSTRY_SUCCESS: {
      return {
        loading: false,
        data: prepareIndustryDoughnutChartData(action.data),
        error: '',
      };
    }
    case GET_PROGRAMS_BY_INDUSTRY_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const employeesByEmployer = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_EMPLOYEES_BY_EMPLOYER_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_EMPLOYEES_BY_EMPLOYER_SUCCESS: {
      return {
        loading: false,
        data: prepareEmployeesDoughnutChartData(action.data),
        error: '',
      };
    }
    case GET_EMPLOYEES_BY_EMPLOYER_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const employerAdoptionRate = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_EMPLOYER_ADOPTION_RATE_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_EMPLOYER_ADOPTION_RATE_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_EMPLOYER_ADOPTION_RATE_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const avgAmountPerUserPerWithdrawal = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_AVG_AMT_PER_USR_PER_WITHDRAWAL_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const avgWithdrawalFrequencyPerUser = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_AVG_WITHDRAWAL_FREQ_PER_USR_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_AVG_WITHDRAWAL_FREQ_PER_USR_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_AVG_WITHDRAWAL_FREQ_PER_USR_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const percentWithdrawalFromAvailBalance = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_PERCENT_WITHDRAWAL_FROM_AVAIL_BAL_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const employerDailyWithdrawalData = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_SUCCESS: {
      return {
        loading: false,
        data: prepareEmployerDailyWithdrawalData(action.data),
        error: '',
      };
    }
    case GET_EMPLOYER_DAILY_WITHDRAWAL_DATA_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  programsByIndustry,
  employeesByEmployer,
  employerAdoptionRate,
  avgAmountPerUserPerWithdrawal,
  avgWithdrawalFrequencyPerUser,
  percentWithdrawalFromAvailBalance,
  employerDailyWithdrawalData,
});
