import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_PROMOTION_BY_ID,
  GET_PROMOTION_BY_ID_LOADING,
  GET_PROMOTION_BY_ID_SUCCESS,
  GET_PROMOTION_BY_ID_ERROR,
  GET_ALL_PROMOTION_CODES,
  GET_ALL_PROMOTION_CODES_LOADING,
  GET_ALL_PROMOTION_CODES_SUCCESS,
  GET_ALL_PROMOTION_CODES_ERROR,
  UPSERT_EMPLOYER_PROMOTION,
  UPSERT_EMPLOYER_PROMOTION_LOADING,
  UPSERT_EMPLOYER_PROMOTION_SUCCESS,
  UPSERT_EMPLOYER_PROMOTION_ERROR,
  UPDATE_PROMOTION_STATUS,
  UPDATE_PROMOTION_STATUS_LOADING,
  UPDATE_PROMOTION_STATUS_SUCCESS,
  UPDATE_PROMOTION_STATUS_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';

function* getPromotionById({ body }) {
  yield put({ type: GET_PROMOTION_BY_ID_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: 'salaryadvance/promotions/byId/{promoId}',
      body,
    });
    yield put({ type: GET_PROMOTION_BY_ID_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_PROMOTION_BY_ID_ERROR, errorMessage: '' });
  }
}

function* getPromotionCodes({ body }) {
  yield put({ type: GET_ALL_PROMOTION_CODES_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: 'salaryadvance/promotions',
      body,
    });
    yield put({ type: GET_ALL_PROMOTION_CODES_SUCCESS, data: data.promotionResponseList });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_ALL_PROMOTION_CODES_ERROR, errorMessage: '' });
  }
}

function* upsertEmployerPromotion({ body }) {
  yield put({ type: UPSERT_EMPLOYER_PROMOTION_LOADING });
  try {
    yield call(api, {
      method: 'POST',
      url: 'salaryadvance/promotions',
      body,
    });
    yield put({ type: UPSERT_EMPLOYER_PROMOTION_SUCCESS, data: { created: true } });
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPSERT_EMPLOYER_PROMOTION_ERROR, errorMessage: '' });
  }
}

function* upsertPromotionStatus({ body }) {
  yield put({ type: UPDATE_PROMOTION_STATUS_LOADING });
  try {
    yield call(api, {
      method: 'PUT',
      url: 'salaryadvance/promotions/status',
      body,
    });
    yield put({ type: UPDATE_PROMOTION_STATUS_SUCCESS, data: { isDisabled: true } });
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_PROMOTION_STATUS_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchPromotionDetailssSaga() {
  yield takeLatest(GET_PROMOTION_BY_ID, getPromotionById);
  yield takeLatest(GET_ALL_PROMOTION_CODES, getPromotionCodes);
  yield takeLatest(UPSERT_EMPLOYER_PROMOTION, upsertEmployerPromotion);
  yield takeLatest(UPDATE_PROMOTION_STATUS, upsertPromotionStatus);
}
