import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_MFA_STATUS,
  GET_MFA_STATUS_LOADING,
  GET_MFA_STATUS_SUCCESS,
  GET_MFA_STATUS_ERROR,
  ASSOCIATE_MFA,
  ASSOCIATE_MFA_LOADING,
  ASSOCIATE_MFA_SUCCESS,
  ASSOCIATE_MFA_ERROR,
  ENABLE_MFA,
  ENABLE_MFA_LOADING,
  ENABLE_MFA_SUCCESS,
  ENABLE_MFA_ERROR,
  DISABLE_MFA,
  DISABLE_MFA_LOADING,
  DISABLE_MFA_SUCCESS,
  DISABLE_MFA_ERROR,
  CLEAR_MFA_DATA,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';

function* getMFAStatus({ body }) {
  yield put({ type: GET_MFA_STATUS_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.mfa.get_mfa_status(body?.username),
      serverless: true,
    });
    yield put({ type: GET_MFA_STATUS_SUCCESS, data: data });
    yield put({ type: CLEAR_MFA_DATA });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_MFA_STATUS_ERROR, errorMessage: '' });
  }
}

function* associateMFA({ body }) {
  yield put({ type: ASSOCIATE_MFA_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.mfa.associate_mfa(),
      serverless: true,
      body: {
        username: body.username,
        accessToken: body.accessToken,
      },
    });

    yield put({ type: ASSOCIATE_MFA_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: ASSOCIATE_MFA_ERROR, errorMessage: '' });
  }
}

function* enableMFA({ body }) {
  yield put({ type: ENABLE_MFA_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.mfa.enable_mfa(),
      serverless: true,
      body: {
        username: body.username,
        code: body.verificationCode,
        accessToken: body.accessToken,
      },
    });

    yield put({ type: ENABLE_MFA_SUCCESS, data: data });
    yield put({
      type: GET_MFA_STATUS_SUCCESS,
      data: {
        mfaEnabled: false,
      },
    });
  } catch (error) {
    errorhandling(error);
    yield put({ type: ENABLE_MFA_ERROR, errorMessage: '' });
  }
}

function* disableMFA({ body }) {
  yield put({ type: DISABLE_MFA_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.mfa.disable_mfa(),
      serverless: true,
      body: {
        accessToken: body.accessToken,
        code: body.verificationCode,
      },
    });

    yield put({ type: DISABLE_MFA_SUCCESS, data: data });
    yield put({
      type: GET_MFA_STATUS_SUCCESS,
      data: {
        mfaEnabled: false,
        mfaType: null,
      },
    });
  } catch (error) {
    errorhandling(error);
    yield put({ type: DISABLE_MFA_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchMFASaga() {
  yield takeLatest(GET_MFA_STATUS, getMFAStatus);
  yield takeLatest(ASSOCIATE_MFA, associateMFA);
  yield takeLatest(ENABLE_MFA, enableMFA);
  yield takeLatest(DISABLE_MFA, disableMFA);
}
