import React from 'react';
import { connect } from 'react-redux';
import { TableComponent } from '../components';
import { isEqual, cloneDeep, isString } from 'lodash';

class Table extends React.Component {
  state = {
    searchText: '',
    currentPageNumber: 1,
    data: cloneDeep(this.props.data),
  };

  componentDidUpdate = (prevProps) => {
    const { data } = this.props;
    if (!isEqual(prevProps.data, data)) {
      this.setState({ data: cloneDeep(data) });
    }
  };

  handleSearchChange = (value) => {
    const { data } = this.props;
    if (value.length > 0) {
      const reg = new RegExp(value, 'gi');
      let filteredData = data.filter((item) => {
        let matchFieldsList = Object.keys(item).filter(
          (element) => item[element] && isString(item[element]) && reg.test(item[element])
        );
        return matchFieldsList.length;
      });
      this.setState({ data: cloneDeep(filteredData), searchText: value });
    } else {
      this.setState({ data: cloneDeep(data), searchText: value });
    }
  };

  render() {
    const { searchText, data } = this.state;
    const {
      columns,
      size,
      bordered,
      total,
      position,
      searchable,
      handlePageChange,
      currentPageNumber,
      loading,
      rowSelection,
      className,
      rowsPerPage,
    } = this.props;
    return (
      <TableComponent
        searchText={searchText}
        columns={columns}
        data={data}
        size={size}
        className={className}
        bordered={bordered}
        total={total}
        position={position}
        searchable={searchable}
        handlePageChange={handlePageChange}
        loading={loading}
        rowSelection={rowSelection}
        currentPageNumber={currentPageNumber}
        rowsPerPage={rowsPerPage}
        handleSearchChange={this.handleSearchChange}
      />
    );
  }
}

const mapStateToProps = ({
  router: {
    location: { pathname },
  },
}) => ({
  pathname,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
