export const GET_INVOICE_BY_ID = 'GET_INVOICE_BY_ID';
export const GET_INVOICE_BY_ID_LOADING = 'GET_INVOICE_BY_ID_LOADING';
export const GET_INVOICE_BY_ID_SUCCESS = 'GET_INVOICE_BY_ID_SUCCESS';
export const GET_INVOICE_BY_ID_ERROR = 'GET_INVOICE_BY_ID_ERROR';

export const SAVE_AND_APPROVE_INVOICE = 'SAVE_AND_APPROVE_INVOICE';
export const SAVE_AND_APPROVE_INVOICE_LOADING = 'SAVE_AND_APPROVE_INVOICE_LOADING';
export const SAVE_AND_APPROVE_INVOICE_SUCCESS = 'SAVE_AND_APPROVE_INVOICE_SUCCESS';
export const SAVE_AND_APPROVE_INVOICE_ERROR = 'SAVE_AND_APPROVE_INVOICE_ERROR';

export const GET_INVOICE_ITEMS = 'GET_INVOICE_ITEMS';
export const GET_INVOICE_ITEMS_LOADING = 'GET_INVOICE_ITEMS_LOADING';
export const GET_INVOICE_ITEMS_SUCCESS = 'GET_INVOICE_ITEMS_SUCCESS';
export const GET_INVOICE_ITEMS_ERROR = 'GET_INVOICE_ITEMS_ERROR';

export const GET_EMPLOYER_PAYPERIOD = 'GET_EMPLOYER_PAYPERIOD';
export const GET_EMPLOYER_PAYPERIOD_LOADING = 'GET_EMPLOYER_PAYPERIOD_LOADING';
export const GET_EMPLOYER_PAYPERIOD_SUCCESS = 'GET_EMPLOYER_PAYPERIOD_SUCCESS';
export const GET_EMPLOYER_PAYPERIOD_ERROR = 'GET_EMPLOYER_PAYPERIOD_ERROR';

export const saveAndApproveInvoice = (body) => ({ type: SAVE_AND_APPROVE_INVOICE, body });
export const getInvoiceById = (body) => ({ type: GET_INVOICE_BY_ID, body });
export const getInvoiceItems = (body) => ({ type: GET_INVOICE_ITEMS, body });
export const getEmployerPayPeriod = (body) => ({ type: GET_EMPLOYER_PAYPERIOD, body });
