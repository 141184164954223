import React from 'react';
import { Card, Col, Form, Switch } from 'antd';

export default function ToggleThemeSection() {
  return (
    <Col span={24}>
      <Card title="Theme Configuration">
        <Form.Item
          name="isEnabled"
          valuePropName="checked"
          label="Enable Theme"
          layout={'horizontal'}>
          <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
        </Form.Item>
      </Card>
    </Col>
  );
}
