import React from 'react';
import { Layout } from 'antd';
import { Route, Switch } from 'react-router-dom';
import '../../../assets/theme/global.css';
import { roleBasedRoutingMap } from '../../../navigation/routes';
import { CustomSidebar, CustomHeader } from '../../../shared';
import { roleBasedConfigMap } from '../../../navigation/menus';
import EmployerBalanceAlert from './EmployerBalanceAlert';

const { Content } = Layout;

const Private = ({
  isSidebarCollapsed,
  toggleSidebar,
  navigateTo,
  path,
  employers,
  selectedEmployer,
  setSelectedEmployer,
  userDetails,
  handleMenuClick,
  handleLogOut,
}) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <CustomSidebar
        isSidebarCollapsed={isSidebarCollapsed}
        navigateTo={navigateTo}
        menu={roleBasedConfigMap[userDetails['custom:UserType']]}
        path={path}
        userDetails={userDetails}
      />
      <Layout>
        <CustomHeader
          isSidebarCollapsed={isSidebarCollapsed}
          toggleSidebar={toggleSidebar}
          employers={employers}
          selectedEmployer={selectedEmployer}
          setSelectedEmployer={setSelectedEmployer}
          userDetails={userDetails}
          isEmployerDropdownVisible={userDetails['custom:UserType'] !== 'EMPLOYER'}
          handleMenuClick={handleMenuClick}
          handleLogOut={handleLogOut}
          path={path}
        />
        <EmployerBalanceAlert
          userType={userDetails['custom:UserType']}
          selectedEmployer={selectedEmployer}
        />

        <Content
          style={{
            margin: '20px 34px 12px 24px',
            padding: 0,
          }}>
          <Switch>
            {roleBasedRoutingMap[userDetails['custom:UserType']] &&
              roleBasedRoutingMap[userDetails['custom:UserType']].map((item, index) => (
                <Route key={index} exact path={item.path} component={item.component} />
              ))}
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Private;
