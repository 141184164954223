import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  GET_EMPLOYER_THEME,
  GET_EMPLOYER_THEME_ERROR,
  GET_EMPLOYER_THEME_LOADING,
  GET_EMPLOYER_THEME_SUCCESS,
  SET_EMPLOYER_THEME,
  UPDATE_EMPLOYER_THEME_CS,
  UPDATE_EMPLOYER_THEME_CS_ERROR,
  UPDATE_EMPLOYER_THEME_CS_SUCCESS,
  UPDATE_EMPLOYER_THEME_LOGO,
  UPDATE_EMPLOYER_THEME_LOGO_ERROR,
  UPDATE_EMPLOYER_THEME_LOGO_LOADING,
  UPDATE_EMPLOYER_THEME_LOGO_SUCCESS,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';

// extract employerTheme data from employer
export const getThemeDataFromEmployer = (employerData) => {
  if (!employerData) return {};
  return {
    employerId: employerData?.id,
    theme: employerData?.settings?.theme,
  };
};

// get employerTheme data from redux store if it exists for the employerId
const getStoredTheme = (employerId) => (state) => {
  const stored = state?.employerTheme?.employerTheme?.data;
  return !stored || !employerId || stored.employerId !== employerId ? {} : stored;
};

function* handleStoredThemeCheck({ employerId, successAction, loadingAction }) {
  // Get theme from redux store (which is persisted)
  const storedTheme = yield select(getStoredTheme(employerId));
  // If we have stored theme, yield it immediately
  if (storedTheme?.employerId) {
    yield put({ type: successAction, data: storedTheme });
  } else {
    // Only show loading state if we don't have stored data
    yield put({ type: loadingAction });
  }
}

function* getEmployerTheme({ body }) {
  yield fork(handleStoredThemeCheck, {
    employerId: body.employerId,
    successAction: GET_EMPLOYER_THEME_SUCCESS,
    loadingAction: GET_EMPLOYER_THEME_LOADING,
  }); // Non-blocking call
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.employers.get_employer({ employerId: body.employerId }),
      body,
    });
    yield put({ type: GET_EMPLOYER_THEME_SUCCESS, data: getThemeDataFromEmployer(data) });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_EMPLOYER_THEME_ERROR, errorMessage: '' });
  }
}

function* updateEmployerThemeColorScheme({ body }) {
  // set the updated theme locally (optimistically update)
  yield put({ type: SET_EMPLOYER_THEME, data: body });

  try {
    const { data } = yield call(api, {
      method: 'PUT',
      url: apiConfig.employers.update_employer_theme_cs({ employerId: body.employerId }),
      body: body.theme,
    });
    yield put({ type: UPDATE_EMPLOYER_THEME_CS_SUCCESS, data: getThemeDataFromEmployer(data) });
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_EMPLOYER_THEME_CS_ERROR, errorMessage: '' });
  }
}

function* updateEmployerThemeLogo({ body }) {
  yield fork(handleStoredThemeCheck, {
    employerId: body.employerId,
    successAction: UPDATE_EMPLOYER_THEME_LOGO_SUCCESS,
    loadingAction: UPDATE_EMPLOYER_THEME_LOGO_LOADING,
  });

  try {
    const { logoFullVersion, logoIconVersion } = body;
    const formData = new FormData();
    // set only non-null files
    logoFullVersion && formData.append('logoFullVersion', logoFullVersion);
    logoIconVersion && formData.append('logoIconVersion', logoIconVersion);

    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.employers.update_employer_theme_logo({ employerId: body.employerId }),
      body: formData,
    });
    yield put({ type: UPDATE_EMPLOYER_THEME_LOGO_SUCCESS, data: getThemeDataFromEmployer(data) });
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_EMPLOYER_THEME_LOGO_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployerThemeSaga() {
  yield takeLatest(GET_EMPLOYER_THEME, getEmployerTheme);
  yield takeLatest(UPDATE_EMPLOYER_THEME_CS, updateEmployerThemeColorScheme);
  yield takeLatest(UPDATE_EMPLOYER_THEME_LOGO, updateEmployerThemeLogo);
}
