import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories } from '../actions';
import BreadcrumbList from '../../../shared/components/Breadcrumb';
import ArticlesComponent from '../components/Articles';
import { ArticlesProvider } from './ArticlesContext';
import { EmployerSelectionPrompt } from '../../../shared';

export default function Articles() {
  const dispatch = useDispatch();
  const selectedEmployer = useSelector((state) => state.shared.selectedEmployer);

  React.useEffect(() => {
    const employerId = selectedEmployer?.value;
    if (employerId) {
      // load the categories for all screens
      dispatch(getAllCategories({ employerId }));
    }
  }, [selectedEmployer?.value]);

  if (!selectedEmployer?.value) {
    return (
      <>
        <BreadcrumbList items={[{ title: 'Articles' }]} />
        <EmployerSelectionPrompt />
      </>
    );
  }

  return (
    <>
      <BreadcrumbList items={[{ title: 'Articles' }]} />
      <ArticlesProvider>
        <ArticlesComponent />
      </ArticlesProvider>
    </>
  );
}
