import { combineReducers } from 'redux';
import {
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  UPDATE_PASSWORD_LOADING,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from './actions';

const initialStateObject = {
  loading: false,
  data: {},
  error: '',
};

const changePasswordTokens = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case CHANGE_PASSWORD_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case CHANGE_PASSWORD_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const resetPasswordTokens = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case RESET_PASSWORD_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case RESET_PASSWORD_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const updatePasswordTokens = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case UPDATE_PASSWORD_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case UPDATE_PASSWORD_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case UPDATE_PASSWORD_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  resetPasswordTokens,
  changePasswordTokens,
  updatePasswordTokens,
});
