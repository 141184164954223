import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_EMPLOYEE_BY_ID,
  GET_EMPLOYEE_BY_ID_LOADING,
  GET_EMPLOYEE_BY_ID_SUCCESS,
  GET_EMPLOYEE_BY_ID_ERROR,
  GET_EMPLOYEE_PAYTIME_INFORMATION,
  GET_EMPLOYEE_PAYTIME_INFORMATION_LOADING,
  GET_EMPLOYEE_PAYTIME_INFORMATION_SUCCESS,
  GET_EMPLOYEE_PAYTIME_INFORMATION_ERROR,
  UPDATE_EMPLOYEE_STATUS,
  UPDATE_EMPLOYEE_STATUS_LOADING,
  UPDATE_EMPLOYEE_STATUS_SUCCESS,
  UPDATE_EMPLOYEE_STATUS_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';

function* getEmployeeById({ body }) {
  yield put({ type: GET_EMPLOYEE_BY_ID_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.employees.get_employee({ employeeId: body.employeeId }),
    });
    // const basicInfo = {
    //     id: 1,
    //     first_name: "",
    //     last_name: "",
    //     email: "",
    //     mobileNumber: "",
    //     birthDate: "",
    //     workNumber: "",
    //     employerName: "",
    //     salary: "",
    //     createdAt: "",
    //     status: "",
    //     address: {
    //         addressLine1: "",
    //         addressLine2: "",
    //         city: "",
    //         country: "",
    //     },
    //     employerName: "",

    // }

    // const bankInfo = {
    //     branchCode: "",
    //     accountNumber: "",
    //     paConnectionId: "",
    //     fastpaymentEnabled: true,

    // }

    // const payrollUserInfo = {
    //     payRolluserId: "123456",
    //     employmentType: "Full Time",
    //     designation: "Software Developer",
    //     payCycleName: "Monthly",
    //     payCalendarStartDate: "2021-09-01",
    //     payCalendarEndDate: "2021-09-30",
    // }
    yield put({
      type: GET_EMPLOYEE_BY_ID_SUCCESS,
      data,
    });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_EMPLOYEE_BY_ID_ERROR, errorMessage: '' });
  }
}

function* getEmployeePaytimeInformation({ body }) {
  yield put({ type: GET_EMPLOYEE_PAYTIME_INFORMATION_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.employees.get_employee({ employeeId: body.employeeId }),
      body,
    });
    yield put({ type: GET_EMPLOYEE_PAYTIME_INFORMATION_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_EMPLOYEE_PAYTIME_INFORMATION_ERROR, errorMessage: '' });
  }
}

function* updateEmployeeStatus({ body }) {
  yield put({ type: UPDATE_EMPLOYEE_STATUS_LOADING });
  try {
    const { employeeId, approvalStatus } = body;
    const path =
      approvalStatus === 'ENABLED'
        ? apiConfig.employees.disable_employee({ employeeId })
        : apiConfig.employees.enable_employee({ employeeId });
    yield call(api, {
      method: 'POST',
      url: path,
      // body
    });
    yield put({ type: UPDATE_EMPLOYEE_STATUS_SUCCESS, data: { isDisabled: true } });
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_EMPLOYEE_STATUS_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployeeDetailsSaga() {
  yield takeLatest(GET_EMPLOYEE_BY_ID, getEmployeeById);
  yield takeLatest(GET_EMPLOYEE_PAYTIME_INFORMATION, getEmployeePaytimeInformation);
  yield takeLatest(UPDATE_EMPLOYEE_STATUS, updateEmployeeStatus);
}
